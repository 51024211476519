import { Injectable } from '@angular/core';
import {
    collection,
    collectionData,
    collectionGroup,
    CollectionReference,
    deleteDoc,
    doc,
    docData,
    DocumentReference,
    Firestore,
    limit,
    orderBy,
    query,
    QueryConstraint,
    runTransaction,
    serverTimestamp,
    setDoc,
    Transaction,
    UpdateData,
    updateDoc,
    where,
    writeBatch,
} from '@angular/fire/firestore';
import ArrayUtils from '@nova-shared/array.utils';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiClientModule } from './api-client.module';

export type OrderByFieldDirection = 'asc' | 'desc';
export type OrderByField = { name: string; direction: OrderByFieldDirection };
@Injectable({
	providedIn: ApiClientModule
})
export class ApiFirestoreClient {
	constructor(private store: Firestore) {}

	public collection<T>(
		collectionName: string,
		queryConstraints: QueryConstraint[] = []
	): Observable<T[]> {
		return collectionData<T>(
			query<T>(this.collectionRef<T>(collectionName), ...queryConstraints)
		);
	}

	public queryWithListFilter<T>(
		list: string[],
		queryFn: (list: string[]) => Observable<T[]>
	): Observable<T[]> {
		if (list.length >= 10) {
			const chunkedList: string[][] = ArrayUtils.chunks<string>(list, 10);
			const queries = [];
			chunkedList.map(chunk => queries.push(queryFn(chunk)));

			return combineLatest<T[]>(queries).pipe(
				map(items => [].concat(...items))
			);
		} else {
			return queryFn(list);
		}
	}

	public collectionRef<T>(collectionName: string): CollectionReference<T> {
		return collection(this.store, collectionName).withConverter<T>(null);
	}

	public item<T>(collectionName: string, id: string): Observable<T> {
		return docData<T>(
			doc(this.store, `${collectionName}/${id}`).withConverter<T>(null)
		);
	}

	public itemRef<T>(
		collectionName: string,
		id: string
	): DocumentReference<T> {
		return doc(this.store, `${collectionName}/${id}`).withConverter<T>(
			null
		);
	}

	public subCollection<T>(
		collectionName: string,
		id: string,
		subCollectionName: string,
		queryConstraints: QueryConstraint[] = []
	): Observable<T[]> {
		return collectionData<T>(
			query<T>(
				this.subCollectionRef(collectionName, id, subCollectionName),
				...queryConstraints
			)
		);
	}

	public subCollectionRef<T>(
		collectionName: string,
		id: string,
		subCollectionName: string
	): CollectionReference<T> {
		return collection(
			this.itemRef<T>(collectionName, id),
			subCollectionName
		).withConverter<T>(null);
	}

	public add<T>(collectionName: string, data: T): Promise<string> {
		let id: string | number;
		if (Object.prototype.hasOwnProperty.call(data, 'id')) {
			id = Object.getOwnPropertyDescriptor(data, 'id').value;
		}

		if (id || id === 0) {
			// Ensure compatibility with Firebase plain object documents
			data = {
				...data,
				...{ id }
			};
		} else {
			data = this.addId(data);
			id = Object.getOwnPropertyDescriptor(data, 'id').value;
		}

		data = this.addServerTimeStampToCreatedAtIfExist<T>(data);

		try {
			return setDoc<T>(
				this.itemRef<T>(collectionName, id.toString()),
				data
			).then(() => Promise.resolve(id.toString()));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public batchAdd<T>(collectionName: string, dataList: T[]): Promise<T[]> {
		const storedDataList = [];
		const dataListArray = dataList.reduce((all, one, i) => {
			const ch = Math.floor(i / 500);
			all[ch] = [].concat(all[ch] || [], one);
			return all;
		}, []);

		const batches = dataListArray.map(dataL => {
			const batch = writeBatch(this.store);

			dataL.forEach((data: T) => {
				let id: string | number;
				if (Object.prototype.hasOwnProperty.call(data, 'id')) {
					id = Object.getOwnPropertyDescriptor(data, 'id').value;
				}
				if (!id && id !== 0) {
					data = this.addId(data);
					id = Object.getOwnPropertyDescriptor(data, 'id').value;
				} else {
					// Ensure compatibility with Firebase plain object documents
					data = { ...data, ...{ id } };
				}

				data = this.addServerTimeStampToCreatedAtIfExist<T>(data);

				batch.set(this.itemRef<T>(collectionName, id.toString()), data);

				storedDataList.push(data);
			});

			return batch;
		});

		try {
			return Promise.all(batches.map(b => b.commit())).then(() =>
				Promise.resolve(storedDataList)
			);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public batchDelete<T>(
		collectionName: string,
		dataList: T[]
	): Promise<void> {
		const dataListArray = dataList.reduce((all, one, i) => {
			const ch = Math.floor(i / 500);
			all[ch] = [].concat(all[ch] || [], one);
			return all;
		}, []);

		const batches = dataListArray.map(dataL => {
			const batch = writeBatch(this.store);

			dataL.forEach(data => {
				const id: string = Object.getOwnPropertyDescriptor(
					data,
					'id'
				).value.toString();

				batch.delete(this.itemRef<T>(collectionName, id));
			});

			return batch;
		});

		return Promise.all(batches.map(b => b.commit())).then(() =>
			Promise.resolve()
		);
	}

	public addId<T>(data: T): T {
		const id = this.createId();
		return { ...data, ...{ id } };
	}

	public createId(): string {
		return doc(collection(this.store, '_')).id;
	}

	public update<T>(
		collectionName: string,
		id: string,
		item: UpdateData<T>
	): Promise<void> {
		if (!id) {
			return Promise.reject();
		}
		item = this.validateUpdateId<UpdateData<T>>(id, item);

		try {
			return updateDoc<T>(this.itemRef<T>(collectionName, id), item);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public delete(collectionName: string, id: string): Promise<void> {
		if (!id) {
			return Promise.resolve();
		}
		try {
			return deleteDoc(this.itemRef(collectionName, id));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public addSubItem<T>(
		collectionName: string,
		id: string,
		subCollectionName: string,
		data: T
	): Promise<string> {
		return this.add<T>(
			`${collectionName}/${id}/${subCollectionName}`,
			data
		);
	}

	public subItem<T>(
		collectionName: string,
		id: string,
		subCollectionName: string,
		subId: string
	): Observable<T> {
		return this.item<T>(
			`${collectionName}/${id}/${subCollectionName}`,
			subId
		);
	}

	public updateSubItem<T>(
		collectionName: string,
		id: string,
		subCollectionName: string,
		subId: string,
		item: UpdateData<T>
	): Promise<void> {
		if (!id) {
			return Promise.reject();
		}
		return this.update<T>(
			`${collectionName}/${id}/${subCollectionName}`,
			subId,
			item
		);
	}

	public deleteSubItem(
		collectionName: string,
		id: string,
		subCollectionName: string,
		subId: string
	): Promise<void> {
		if (!id) {
			return Promise.resolve();
		}
		return this.delete(
			`${collectionName}/${id}/${subCollectionName}`,
			subId
		);
	}

	public runTransaction(
		updateFunction: (transaction: Transaction) => Promise<unknown>
	): Promise<unknown> {
		try {
			return runTransaction(this.store, updateFunction);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	/* collectionGroup */
	public collectionGroup<T>(
		collectionName: string,
		queryConstraints: QueryConstraint[] = []
	): Observable<T[]> {
		return collectionData<T>(
			query<T>(
				collectionGroup(this.store, collectionName).withConverter<T>(
					null
				),
				...queryConstraints
			)
		);
	}

	public collectionGroupItem<T>(
		collectionName: string,
		id?: string | number,
		queryConstraints: QueryConstraint[] = []
	): Observable<T> {
		return this.collectionGroup<T>(
			collectionName,
			id || id === 0
				? [where('id', '==', id), limit(1)]
				: queryConstraints
		).pipe(switchMap(l => of(l.length ? l[0] : null)));
	}

	public buildOrderBy(orderByFields?: OrderByField[]): QueryConstraint[] {
		return (
			orderByFields?.map(field => orderBy(field.name, field.direction)) ??
			[]
		);
	}

	private validateUpdateId<T>(id: string, item: T): T {
		let validatedId: string | number = id;
		if (Object.prototype.hasOwnProperty.call(item, 'id')) {
			validatedId = Object.getOwnPropertyDescriptor(item, 'id').value;
			validatedId = validatedId?.toString() === id ? validatedId : id;
		}
		// Ensure compatibility with Firebase plain object documents
		return { ...item, ...{ id: validatedId } };
	}

	// if createdAt exist, then add server timestamp
	private addServerTimeStampToCreatedAtIfExist<T>(data: T): T {
		if (
			Object.prototype.hasOwnProperty.call(data, 'createdAt') &&
			!(data as any).createdAt
		)
			return {
				...data,
				createdAt: serverTimestamp()
			};
		else {
			return data;
		}
	}
}
