<ng-container *ngIf="type !== 'avatar'; else avatar">
	<ng-container [ngTemplateOutlet]="loading"></ng-container>
	<ion-img
		*ngIf="isLazyLoadingImg; else staticImg"
		(click)="showLightbox()"
		(ionImgDidLoad)="onImageDidLoad()"
		[src]="imgSrc"
		[class.clickable]="enableLightbox"
		class="photo"
	></ion-img>
</ng-container>
<ng-template #avatar>
	<ion-avatar>
		<ng-container *ngIf="imgSrc; else icon">
			<ng-container [ngTemplateOutlet]="loading"></ng-container>
			<ion-img
				*ngIf="isLazyLoadingImg; else staticImg"
				(click)="showLightbox()"
				(ionImgDidLoad)="onImageDidLoad()"
				[src]="imgSrc"
				[class.clickable]="enableLightbox"
			></ion-img>
		</ng-container>
		<ng-template #icon>
			<ng-container [ngTemplateOutlet]="loading"></ng-container>
			<ion-icon *ngIf="!isLazyLoadingImg" name="person"></ion-icon>
		</ng-template>
	</ion-avatar>
</ng-template>

<ng-template #staticImg>
	<div class="static-img">
		<img
			*ngIf="imgSrc"
			(click)="showLightbox()"
			(load)="onImageDidLoad()"
			[src]="imgSrc"
			[class.clickable]="enableLightbox"
		/>
	</div>
</ng-template>
<ng-template #loading>
	<div *ngIf="isLoading" class="loading-photo">
		<ion-spinner></ion-spinner>
	</div>
</ng-template>

<app-lightbox #lightbox *ngIf="enableLightbox" [imgSrc]="imgSrc"></app-lightbox>
