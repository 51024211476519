import { EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';

import { AppBannerComponent } from './app-banner.component';

export interface AppBannerSettings {
	viewContainerRef: ViewContainerRef | null;
	disabled?: boolean;
	daysHidden?: number | null;
	icon?: {
		ios?: string;
		android?: string;
	};
	buttonLabel?: string;
	buttonUrl: {
		ios: string;
		android: string;
	};
	title?: string;
}

export const DEFAULT_SETTINGS: AppBannerSettings = {
	disabled: false,
	viewContainerRef: null,
	daysHidden: 14,
	buttonLabel: 'appBanner.button',
	title: 'appBanner.title',
	icon: {
		ios: 'assets/icons/apple-touch-icon-120x120.png',
		android: 'assets/icons/icon-128x128.png'
	},
	buttonUrl: {
		ios: '#',
		android: '#'
	}
};

const APP_BANNER_CLOSED_COOKIE = 'app_banner_closed';

@Injectable({
	providedIn: 'root'
})
export class AppBannerService {
	public settings: AppBannerSettings;
	public onClose: EventEmitter<void>;
	private appBanner: AppBannerComponent = null;

	constructor(
		private readonly cookieService: CookieService,
		private readonly platform: Platform
	) {
		this.onClose = new EventEmitter();
		this.settings = DEFAULT_SETTINGS;
	}

	public attachAppBanner(settings: AppBannerSettings): void {
		this.settings = { ...this.settings, ...settings };

		if (
			this.settings.disabled ||
			!this.isMobileBrowserPlatform ||
			this.cookieService.check(APP_BANNER_CLOSED_COOKIE)
		) {
			return;
		}

		if (this.appBanner === null) {
			const componentReference =
				this.settings.viewContainerRef.createComponent(
					AppBannerComponent
				);
			componentReference.instance.componentRef = componentReference;
			this.appBanner = componentReference.instance;
			this.appBanner.onClose.pipe(take(1)).subscribe(() => {
				this.cookieService.set(
					APP_BANNER_CLOSED_COOKIE,
					'1',
					this.getHiddenDays()
				);
				this.onClose.emit();
			});
		}

		this.appBanner.settings = this.settings;
	}

	private getHiddenDays(): number | null {
		return this.settings.daysHidden;
	}

	private get isMobileBrowserPlatform(): boolean {
		const isMobilePlatform =
			this.platform.is('android') || this.platform.is('ios');
		return !Capacitor.isNativePlatform() && isMobilePlatform;
	}
}
