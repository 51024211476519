import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { environment } from '@nova-environments/environment';
import { Subscription } from 'rxjs';

import { AppVersion } from './app-version';
import { AppVersionService } from './app-version.service';

@Injectable({
	providedIn: 'root'
})
export class AppVersionController {
	private deviceVersionNumber: string;
	private minVersionNumber: string;
	private activeAppVersion$: Subscription;

	constructor(
		private appVersionService: AppVersionService,
		private alertController: AlertController,
		private i18n: I18nService
	) {}

	public static compareAppVersions(
		v1: string,
		comparator: string,
		v2: string
	): boolean {
		if (
			['==', '===', '<', '<=', '>', '>=', '!=', '!=='].indexOf(
				comparator
			) === -1
		) {
			throw new Error(`Invalid comparator. ${comparator}`);
		}
		const v1parts = v1.split('.');
		const v2parts = v2.split('.');
		const maxLen = Math.max(v1parts.length, v2parts.length);
		let part1;
		let part2;
		let cmp = 0;
		for (let i = 0; i < maxLen && !cmp; i++) {
			part1 = parseInt(v1parts[i], 10) || 0;
			part2 = parseInt(v2parts[i], 10) || 0;
			if (part1 < part2) {
				cmp = 1;
			}
			if (part1 > part2) {
				cmp = -1;
			}
		}
		return eval(`0${comparator}${cmp}`);
	}

	public async initListener(): Promise<void> {
		if (!Capacitor.isNativePlatform()) {
			return Promise.resolve();
		}

		this.deviceVersionNumber = await this.getDeviceVersionCode();

		if (this.activeAppVersion$) {
			this.activeAppVersion$.unsubscribe();
		}

		this.activeAppVersion$ = this.appVersionService
			.get()
			.subscribe((version: AppVersion) => {
				this.minVersionNumber =
					Capacitor.getPlatform() === 'ios'
						? version.min_ios_version
						: Capacitor.getPlatform() === 'android'
						? version.min_android_version
						: null;

				this.verifyNeedUpdate();
			});
	}

	public async getDeviceVersionCode(): Promise<string> {
		if (Capacitor.isNativePlatform()) {
			const info = await App.getInfo();
			return info.version;
		} else {
			return Promise.resolve(environment.version);
		}
	}

	private verifyNeedUpdate(): void {
		if (!this.minVersionNumber) {
			return null;
		}

		const needUpdate = !AppVersionController.compareAppVersions(
			this.minVersionNumber,
			'<=',
			this.deviceVersionNumber
		);
		if (environment.clientLogEnabled) {
			console.log(
				// eslint-disable-next-line max-len
				`Min version: ${this.minVersionNumber} - Device version: ${
					this.deviceVersionNumber
				} -> Needs update: ${needUpdate ? 'yes' : 'no'}`
			);
		}

		if (needUpdate) {
			this.presentAlert();
		}
	}

	private async presentAlert(): Promise<void> {
		const alert = await this.alertController.create({
			cssClass: 'alert',
			header: await this.i18n.get('appVersion.updateTitle'),
			message: await this.i18n.get('appVersion.updateMessage'),
			backdropDismiss: false,
			translucent: true,
			buttons: [
				{
					text: await this.i18n.get('appVersion.button'),
					handler: async () => {
						if (Capacitor.getPlatform() === 'ios') {
							if (environment.appStoreAppUrl)
								await Browser.open({
									url: environment.appStoreAppUrl
								});
						} else if (Capacitor.getPlatform() === 'android') {
							if (environment.playStoreAppUrl)
								await Browser.open({
									url: environment.playStoreAppUrl
								});
						} else {
							return null;
						}

						this.verifyNeedUpdate();
					}
				}
			]
		});

		alert.present();
	}
}
