import { Observable } from 'rxjs';

import { TimeoutError } from './timeout.error';

export const nameof = <T>(property: keyof T): string => property.toString();

// instanceof does not always work for Observable, maybe because of different RxJs versions of libraries
export const instanceOfObservable = (
	object: any
): object is Observable<any> => {
	return object && typeof object === 'object' && 'source' in object;
};

export const promiseWithTimeout = <T>(
	prom: Promise<T>,
	timeout: number
): Promise<T> => {
	let timer;
	return Promise.race([
		prom,
		new Promise<T>(
			(_resolve, reject) =>
				(timer = setTimeout(
					reject,
					timeout,
					new TimeoutError('TIMEOUT')
				))
		)
	]).finally(() => clearTimeout(timer));
};

export const delayedPromise = (delay: number): Promise<void> =>
	new Promise(resolve => setTimeout(resolve, delay));

export const formatString = (
	str: string | null,
	params: object
): string | null => {
	if (!str) {
		return null;
	}

	Array.from(Object.keys(params)).forEach(
		k =>
			(str = str.replace(
				new RegExp(`{{\\s*${k}\\s*}}`),
				params[k] as string
			))
	);
	return str;
};
