import { Component, NgZone, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { AuthService } from '@nova-core/auth/auth.service';
import { FcmService } from '@nova-core/fcm/fcm.service';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { MessagingController } from '@nova-features/messaging/messaging.controller';
import { UserService } from '@nova-features/user/user.service';
import { AppBannerService } from '@nova-shared/app-banner/app-banner.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AppVersionController } from './core/app-version/app-version.controller';
import { ThemeService } from './shared/theme/theme.service';

declare const window: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
	public swipeGesturesEnabled = true;
	private destruction = new Subject<void>();

	constructor(
		private platform: Platform,
		private router: Router,
		themeService: ThemeService,
		private zone: NgZone,
		private messaging: MessagingController,
		private appVersionController: AppVersionController,
		private i18nService: I18nService,
		private viewContainerRef: ViewContainerRef,
		private appBannerService: AppBannerService,
		private authService: AuthService,
		private userService: UserService,
		private fcmService: FcmService
	) {
		if (environment.clientLogEnabled) {
			console.log(
				`isNative: ${
					Capacitor.isNativePlatform() ? 'yes' : 'no'
				} (${Capacitor.getPlatform()}) - Platform: ${
					this.platform.is('ios')
						? 'ios'
						: this.platform.is('android')
						? 'android'
						: 'desktop?'
				}`
			);
		}
		this.swipeGesturesEnabled = Capacitor.isNativePlatform();

		// Switch to Dark Mode, if enabled
		themeService.themeMode
			.pipe(takeUntil(this.destruction))
			.subscribe(mode => this.toggleDarkTheme(mode === 'dark'));

		this.initializeApp();
	}

	public ngOnDestroy(): void {
		this.destruction.next();
		this.destruction.complete();
	}

	private initializeApp(): void {
		this.platform.ready().then(() => {
			if (Capacitor.isNativePlatform()) {
				StatusBar.setStyle({ style: Style.Dark });
				if (this.platform.is('android')) {
					StatusBar.setOverlaysWebView({ overlay: true });

					// Fix Capacitor bug when web view overlay is enabled. On Android the keyboard then overlaps the
					// bottom inputs.
					this.handleKeyboardToggle();
				}

				// detect if the application is foreground or background
				this.runAppStateChangeListener();
			}

			// Safe area is not set correctly by Ionic / Capacitor by default, especially for devices with a notch
			if (window.AndroidNotch) {
				this.setAndroidSafeArea();
			}

			SplashScreen.hide();

			// App is opened by URL. Eg. start check-in when location code was scanned by camera.
			if (Capacitor.isPluginAvailable('App')) {
				App.addListener('appUrlOpen', (data: URLOpenListenerEvent) =>
					this.handleAppLink(data.url)
				);
			}

			// Listener checks the minimum requirement for the app version and verifies it
			this.appVersionController.initListener();

			// Listener to show upcomming messages
			this.messaging.initListener();

			if (!window.location.pathname.includes('qr-printer'))
				// Init App banner
				this.appBannerService.attachAppBanner({
					viewContainerRef: this.viewContainerRef,
					buttonUrl: {
						ios: environment.appStoreAppUrl,
						android: environment.playStoreAppUrl
					}
				});

			// Init user after login
			this.authService.loggedInUserId
				.pipe(filter(() => this.authService.isRegistered()))
				.subscribe(() => {
					this.initLoggedInUser();
				});
		});
	}

	private handleKeyboardToggle(): void {
		Keyboard.addListener('keyboardDidShow', keyboardInfo => {
			const style =
				document.documentElement.querySelector('ion-app').style;
			style.setProperty(
				'margin-bottom',
				`${keyboardInfo.keyboardHeight}px`
			);
		});
		Keyboard.addListener('keyboardWillHide', () => {
			const style =
				document.documentElement.querySelector('ion-app').style;
			style.setProperty('margin-bottom', '0px');
		});
	}

	private setAndroidSafeArea(): void {
		const minStatusBarHeight = 25;
		const minNavigationBarHeight = 50;
		const style = document.documentElement.style;
		window.AndroidNotch.getInsetTop(px => {
			style.setProperty(
				'--ion-safe-area-top',
				`${Math.max(minStatusBarHeight, +px)}px`
			);
		});
		window.AndroidNotch.getInsetBottom(px =>
			style.setProperty(
				'--ion-safe-area-bottom',
				`${Math.max(minNavigationBarHeight, +px)}px`
			)
		);
		window.AndroidNotch.getInsetLeft(px =>
			style.setProperty('--ion-safe-area-left', `${px as string}px`)
		);
		window.AndroidNotch.getInsetRight(px =>
			style.setProperty('--ion-safe-area-right', `${px as string}px`)
		);
	}

	private handleAppLink(link: string): void {
		if (!link) {
			return;
		}

		this.zone.run(() => {
			const url = new URL(link);
			if (environment.allowedAppLinkingHosts.includes(url.host)) {
				if (url.pathname) {
					this.router.navigateByUrl(url.pathname + url.search, {
						replaceUrl: true
					});
				}
			}
		});
	}

	private toggleDarkTheme(shouldAdd: boolean): void {
		document.body.classList.toggle('dark', shouldAdd);
	}

	private runAppStateChangeListener(): void {
		App.addListener('appStateChange', (state: AppState) => {
			if (state.isActive) {
				// App has become active
				this.i18nService.setLanguageByDevice();
			} else {
				// App has become inactive
			}
		});
	}

	private async initLoggedInUser(): Promise<void> {
		const loggedInUser = this.authService.getCurrentLoggedInUser();
		if (!loggedInUser) {
			return Promise.resolve();
		}

		if (environment.clientLogEnabled) {
			console.log(
				`Init registered user (${loggedInUser?.id}) after login`
			);
		}

		// Init Push by setting deviceToken after user login (this also updates the user locale data)
		const userUpdated = await this.fcmService.register(false);

		// If the user was not updated due Push initialization, update just the locale data
		if (!userUpdated) {
			this.userService.updateLocaleData(loggedInUser);
		}
	}
}
