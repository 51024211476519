import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@nova-shared/directives/directives.module';
import { SwiperModule } from 'swiper/angular';

import { CameraPreviewComponent } from './camera-preview/camera-preview.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { PhotoUploaderComponent } from './photo-uploader/photo-uploader.component';
import { PhotoComponent } from './photo/photo.component';

@NgModule({
	declarations: [
		PhotoUploaderComponent,
		PhotoComponent,
		LightboxComponent,
		CameraPreviewComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		DirectivesModule,
		TranslateModule,
		SwiperModule
	],
	exports: [
		PhotoUploaderComponent,
		PhotoComponent,
		LightboxComponent,
		CameraPreviewComponent
	]
})
export class CameraModule {}
