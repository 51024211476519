import { Timestamp } from '@angular/fire/firestore';
import { ApiFirebaseClient } from '@nova-core/api-client/api-firebase-client.service';

import { ServerTimeRepository } from './server-time-repository';

export class ServerTimeFirebaseRepository implements ServerTimeRepository {
	private static readonly FUNCTION_GET_SERVER_TIME = 'serverTime';

	constructor(private fns: ApiFirebaseClient) {}

	public get(): Promise<Timestamp> {
		return this.fns
			.call<void, { serverTime: Timestamp }>(
				ServerTimeFirebaseRepository.FUNCTION_GET_SERVER_TIME
			)
			.then(response => response.serverTime);
	}
}
