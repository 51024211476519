import { NgModule } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
    connectFirestoreEmulator,
    FirestoreSettings,
    initializeFirestore,
    provideFirestore,
} from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from '@nova-environments/environment';

@NgModule({
	declarations: [],
	imports: [
		provideFirestore(() => {
			// "useFetchStreams" was enabled by default with version 9, but it sometimes (about each 3-8 time)
			// results in a network error during auth initialization (fetching user from DB) on app start,
			// if an user was loggedin.
			const firestoreSettings: FirestoreSettings & {
				useFetchStreams: boolean;
			} = {
				useFetchStreams: false,
				ignoreUndefinedProperties: true
			};
			const firestore = initializeFirestore(getApp(), firestoreSettings);
			if (environment.useDBEmulator) {
				console.log('Use DB Emulator');
				connectFirestoreEmulator(firestore, 'localhost', 8080);
			}
			return firestore;
		}),
		provideStorage(() => {
			const storage = getStorage();
			if (environment.useStorageEmulator) {
				console.log('Use Storage Emulator');
				connectStorageEmulator(storage, 'localhost', 9199);
			}
			return storage;
		}),
		provideFunctions(() => {
			const functions = getFunctions(undefined, 'europe-west3');
			if (environment.useFunctionsEmulator) {
				console.log('Use Functions Emulator');
				connectFunctionsEmulator(functions, 'localhost', 5001);
			}
			return functions;
		})
	]
})
export class ApiClientModule {}
