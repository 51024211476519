import { Injectable, NgZone } from '@angular/core';
import {
    ActionCodeSettings,
    applyActionCode,
    Auth,
    AuthCredential,
    AuthProvider,
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    getRedirectResult,
    GoogleAuthProvider,
    linkWithCredential,
    linkWithRedirect,
    OAuthProvider,
    onAuthStateChanged,
    reauthenticateWithCredential,
    reauthenticateWithRedirect,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInAnonymously,
    signInWithCredential,
    signInWithEmailAndPassword,
    signInWithRedirect,
    signOut,
    updatePassword,
    useDeviceLanguage,
    User as FirebaseUser,
    verifyPasswordResetCode,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Platform } from '@ionic/angular';
import { AppVersionService } from '@nova-core/app-version/app-version.service';
import { extendPermissions } from '@nova-core/auth/permissions/permission';
import { ErrorHandlingService } from '@nova-core/error-handling.service';
import { environment } from '@nova-environments/environment';
import { User } from '@nova-features/user/user';
import { UserDefaultsService } from '@nova-features/user/user-defaults.service';
import { UserService } from '@nova-features/user/user.service';
import { NotificationService } from '@nova-shared/notification.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { firstValueFrom, from, Observable, of, ReplaySubject, Subject, Subscription, TimeoutError } from 'rxjs';
import { switchMap, take, takeUntil, timeout } from 'rxjs/operators';

export class UserTuple {
	constructor(public user: User, public firebaseUser: FirebaseUser) {}
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public static PASSWORD_STRENGTH_REGEX =
		'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"§$%&/()=?*+#<>@]).{8,}';
	public static PASSWORD_STRENGTH_SPECIAL_CHARS = '!"§$%&/()=?*+#<>@';

	private static REDIRECT_URL_STORAGE_KEY = 'login_redirect_url';
	private static REDIRECT_REGISTER_URL_STORAGE_KEY = 'register_redirect_url';
	private static PROVIDER_ID_GOOGLE = 'google.com';
	private static PROVIDER_ID_APPLE = 'apple.com';
	private static PROVIDER_ID_PASSWORD = 'password';

	public loggedInUser: Observable<User>;
	public loggedInUserId: Observable<string>;
	public tokenRefreshed: Observable<boolean>;

	private loggedInUserSubscription: Subscription;
	private loggedInUserSubject = new ReplaySubject<User>(1);
	private loggedInUserIdSubject = new ReplaySubject<string>(1);
	private currentLoggedInUser: User;
	private currentLoggedInUserProviderId: string;
	private lastNativeLoginDisplayName: string = null;
	private authStateChangeInProgress = false;
	private isLoginInProgress = false;
	private tokenRefreshedSubject = new Subject<boolean>();

	constructor(
		private auth: Auth,
		private userService: UserService,
		private permissionsService: NgxPermissionsService,
		private userDefaultsService: UserDefaultsService,
		private appVersionService: AppVersionService,
		private router: Router,
		private platform: Platform,
		private ngZone: NgZone,
		private notificationService: NotificationService,
		private errorHandlingService: ErrorHandlingService
	) {
		this.loggedInUserIdSubject.next(null);
		this.loggedInUser = this.loggedInUserSubject.asObservable();
		this.loggedInUserId = this.loggedInUserIdSubject.asObservable();
		this.tokenRefreshed = this.tokenRefreshedSubject.asObservable();
	}

	public init(): Promise<boolean> {
		return (
			this.platform
				.ready()
				.then(() => useDeviceLanguage(this.auth))
				.then(() => this.initAuthStateHandler())
				// Load current Firebase user to ensure that user is loaded before first login check
				.then(() => this.processLoginReturnResult())
				.catch(error => {
					if (error?.code === 'auth/user-cancelled') {
						return Promise.resolve(false);
					}

					this.processError(error, true);
					return signOut(this.auth).then(() =>
						Promise.resolve(false)
					);
				})
		);
	}

	public isLoggedIn(): boolean {
		return !!this.currentLoggedInUser;
	}

	public isLoggedInNonAnonymous(): boolean {
		return this.isLoggedIn() && !this.currentLoggedInUser.temporary;
	}

	public isLoggedInAnonymous(): boolean {
		return this.isLoggedIn() && this.currentLoggedInUser.temporary;
	}

	public isLoggedInWithPassword(): boolean {
		return (
			this.isLoggedIn() &&
			this.currentLoggedInUserProviderId ===
				AuthService.PROVIDER_ID_PASSWORD
		);
	}

	public isAccountActivated(): boolean {
		return (
			this.isLoggedIn() &&
			(this.currentLoggedInUserProviderId !==
				AuthService.PROVIDER_ID_PASSWORD ||
				this.currentLoggedInUser.emailVerified)
		);
	}

	public isRegistered(): boolean {
		return User.isRegistered(this.currentLoggedInUser);
	}

	public isAnonymousAppleMail(): boolean {
		return (
			this.isLoggedIn() &&
			this.currentLoggedInUserProviderId ===
				AuthService.PROVIDER_ID_APPLE &&
			!!this.currentLoggedInUser?.email?.match(
				/.*@privaterelay.appleid.com$/i
			)
		);
	}

	public getCurrentLoggedInUser(): User {
		return this.currentLoggedInUser;
	}

	public showLogin(origin?: string): void {
		if (environment.clientLogEnabled) {
			console.log(`Show login with origin: ${origin}`);
		}
		this.setRedirectUrl(origin);
		this.router.navigateByUrl('/login', { replaceUrl: true });
	}

	public showPasswordReset(): void {
		this.router.navigateByUrl('/login/reset-password', {
			replaceUrl: true
		});
	}

	public showAccountActivation(origin?: string): void {
		if (!this.isAccountActivated()) {
			if (environment.clientLogEnabled) {
				console.log(`Show account activation with origin: ${origin}`);
			}
			this.setRegistrationRedirectUrl(origin);
			this.router.navigateByUrl('/registration/activate-account', {
				replaceUrl: true
			});
		}
	}

	public showRegistration(origin?: string): void {
		if (environment.clientLogEnabled) {
			console.log(`Show registration with origin: ${origin}`);
		}
		this.setRegistrationRedirectUrl(origin);
		this.router.navigateByUrl('/registration', { replaceUrl: true });
	}

	public loginAnonymously(): Promise<boolean> {
		this.lastNativeLoginDisplayName = null;
		if (!this.isLoggedIn()) {
			return signInAnonymously(this.auth)
				.then(() => Promise.resolve(true))
				.catch(err => {
					this.processError(err);
					return Promise.resolve(false);
				});
		}
		return Promise.resolve(true);
	}

	public createLogin(email: string, password: string): Promise<boolean> {
		return createUserWithEmailAndPassword(this.auth, email, password)
			.then(() => this.sendEmailVerification())
			.catch(err => {
				this.processError(err);
				return Promise.resolve(false);
			});
	}

	public login(email: string, password: string): Promise<boolean> {
		this.isLoginInProgress = true;
		this.lastNativeLoginDisplayName = null;
		return signInWithEmailAndPassword(this.auth, email, password)
			.then(user => Promise.resolve(!!user))
			.catch(err => {
				this.processError(err);
				return Promise.resolve(false);
			});
	}

	public applyActionCode(code: string): Promise<void> {
		return applyActionCode(this.auth, code).then(() => {
			if (
				this.currentLoggedInUserProviderId ===
				AuthService.PROVIDER_ID_PASSWORD
			) {
				this.currentLoggedInUser.emailVerified = true;
			}
			return Promise.resolve();
		});
	}

	public sendEmailVerification(): Promise<boolean | null> {
		return this.auth.currentUser
			? sendEmailVerification(
					this.auth.currentUser,
					this.buildActionCodeSettings()
			  )
					.then(() => Promise.resolve(true))
					.catch(err => {
						this.processError(err);
						return Promise.resolve(false);
					})
			: Promise.resolve(null);
	}

	public async updatePasswordForEmail(
		currentPassword: string,
		newPassword: string
	): Promise<void> {
		const user = this.auth.currentUser;
		const cred = EmailAuthProvider.credential(user.email, currentPassword);
		await reauthenticateWithCredential(user, cred);
		return updatePassword(user, newPassword);
	}

	public sendPasswordResetEmail(email: string): Promise<boolean> {
		return sendPasswordResetEmail(
			this.auth,
			email,
			this.buildActionCodeSettings()
		)
			.then(() => Promise.resolve(true))
			.catch(err => {
				// Do not provide info about valid email accounts for security reasons
				if (err?.code === 'auth/user-not-found') {
					return Promise.resolve(true);
				}
				this.processError(err);
				return Promise.resolve(false);
			});
	}

	public verifyPasswordResetActionCode(
		actionCode: string
	): Promise<string | void> {
		return verifyPasswordResetCode(this.auth, actionCode);
	}

	public async resetPasswordByActionCode(
		actionCode: string,
		newPassword: string
	): Promise<void> {
		try {
			await confirmPasswordReset(this.auth, actionCode, newPassword);
		} catch (err) {
			return this.processError(err);
		}
	}

	public loginWithGoogle(): Promise<void> {
		this.lastNativeLoginDisplayName = null;
		if (Capacitor.isNativePlatform()) {
			this.isLoginInProgress = true;
			return this.loginWithGoogleNative();
		}

		return this.doOAuthLogin(
			this.providerFor(AuthService.PROVIDER_ID_GOOGLE)
		);
	}

	public loginWithApple(): Promise<void> {
		this.lastNativeLoginDisplayName = null;
		if (Capacitor.isNativePlatform()) {
			this.isLoginInProgress = true;
			return this.loginWithAppleNative();
		}

		return this.doOAuthLogin(
			this.providerFor(AuthService.PROVIDER_ID_APPLE)
		);
	}

	public buildWaitUntilTokenRefreshPromise(
		destruction: Subject<void>
	): Promise<void> {
		return new Promise((resolve, reject) =>
			this.tokenRefreshed
				.pipe(take(1), timeout(20000), takeUntil(destruction))
				.subscribe({
					next: () => resolve(),
					error: e =>
						e instanceof TimeoutError ? resolve() : reject()
				})
		);
	}

	public redirectAfterRegistration(replaceUrl: boolean = true): boolean {
		const redirectUrl = this.getRegistrationRedirectUrl();

		if (redirectUrl) {
			this.router.navigateByUrl(
				(redirectUrl.startsWith('/') ? '' : '/') + redirectUrl,
				{ replaceUrl }
			);
			this.setRegistrationRedirectUrl(null);
			return true;
		}

		return false;
	}

	public relogin(): Promise<FirebaseUser> {
		return this.reloginFor(this.auth.currentUser);
	}

	public async logout(): Promise<boolean> {
		try {
			await signOut(this.auth);
			window.location.href = '/login/logout';
			return true;
		} catch (err) {
			await this.processError(err);
			return false;
		}
	}

	private initAuthStateHandler(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			onAuthStateChanged(
				this.auth,
				firebaseUser => {
					this.ngZone.run(async () => {
						await this.notificationService.showSpinner();
						this.authStateChangeInProgress = true;
						if (environment.clientLogEnabled) {
							console.log(
								'authState changed: ',
								firebaseUser?.uid
							);
						}
						if (this.loggedInUserSubscription) {
							this.loggedInUserSubscription.unsubscribe();
						}
						this.loggedInUserSubscription = this.processUser(
							firebaseUser
						).subscribe({
							next: userTuple => {
								this.ngZone.run(async () => {
									await this.notificationService.hideSpinner();
									const providerId = this.getUserProviderId(
										userTuple?.firebaseUser
									);
									await this.setCurrentUser(
										userTuple?.user,
										providerId
									);

									if (this.authStateChangeInProgress) {
										this.redirectAfterLogin();
									}
									this.authStateChangeInProgress = false;
									this.isLoginInProgress = false;
									resolve(true);
								});
							},
							error: error => {
								this.ngZone.run(async () => {
									// Ignore error if processing was canceled
									if (error) {
										this.processError(error, true);
									} else {
										await this.notificationService.hideSpinner();
									}
									this.authStateChangeInProgress = false;
									this.isLoginInProgress = false;
									resolve(false);
								});
							}
						});
					});
				},
				error => {
					this.authStateChangeInProgress = false;
					this.isLoginInProgress = false;
					return this.notificationService
						.hideSpinner()
						.then(() => reject(error));
				}
			);
		});
	}

	private processLoginReturnResult(): Promise<boolean> {
		return this.userDefaultsService
			.getCurrentUserProviderId()
			.then(currentUserProviderId => {
				// Check for invalid native credentials. See AppDelegate.swift in iOS for implementation
				if (
					currentUserProviderId &&
					currentUserProviderId === 'INVALID'
				) {
					return this.userDefaultsService
						.setCurrentUserProviderId(null)
						.then(() => this.logout());
				}

				if (Capacitor.isNativePlatform()) {
					return Promise.resolve(true);
				}
				// Check result after redirect back from Identity Provider on web
				return getRedirectResult(this.auth).then(() => {
					this.userDefaultsService.setCurrentUserProviderId(null);
					return Promise.resolve(true);
				});
			});
	}

	private buildActionCodeSettings(): ActionCodeSettings {
		let redirectUrl =
			this.getRedirectUrl() ?? this.getRegistrationRedirectUrl();
		redirectUrl =
			redirectUrl && !redirectUrl.startsWith('/')
				? '/' + redirectUrl
				: redirectUrl ?? '';
		const actionCodeSettings: ActionCodeSettings = {
			url: `https://${
				environment.allowedAppLinkingHosts[0] as string
			}${redirectUrl}`,
			handleCodeInApp: true
		};
		return actionCodeSettings;
	}

	private loginWithGoogleNative(): Promise<void> {
		return GoogleAuth.signIn()
			.then(googleUser => {
				const credential = GoogleAuthProvider.credential(
					googleUser.authentication.idToken
				);
				return this.loginToFirebase(credential);
			})
			.catch(error => {
				return error?.message &&
					(error.message as string).includes('canceled')
					? Promise.resolve()
					: this.platform.is('ios')
					? this.processError(error)
					: this.notificationService.showMessage(
							'auth.messages.errorOccurredOrLoginWasCancelled'
					  );
			});
	}

	private loginWithAppleNative(): Promise<void> {
		// clientId and redirectURI are only used by the web plugin of SignInWithApple, which we do not use
		const options: SignInWithAppleOptions = {
			clientId: '',
			redirectURI: '',
			scopes: 'email name'
		};
		return SignInWithApple.authorize(options)
			.then(response => {
				const provider = new OAuthProvider('apple.com');
				const credential = provider.credential({
					idToken: response.response.identityToken
				});
				this.userDefaultsService.setCurrentUserProviderId(
					response.response.user
				);

				this.lastNativeLoginDisplayName = `${response.response.givenName} ${response.response.familyName}`;

				return this.loginToFirebase(credential);
			})
			.catch(error =>
				error?.message && (error.message as string).includes('1001.')
					? Promise.resolve()
					: this.processError(error)
			);
	}

	private providerFor(providerId: string): AuthProvider {
		switch (providerId) {
			case AuthService.PROVIDER_ID_PASSWORD:
				return new EmailAuthProvider();
			case AuthService.PROVIDER_ID_GOOGLE:
				return new GoogleAuthProvider();
			case AuthService.PROVIDER_ID_APPLE:
				return new OAuthProvider(AuthService.PROVIDER_ID_APPLE);
		}
		return null;
	}

	private doOAuthLogin(provider: AuthProvider): Promise<void> {
		return (
			this.auth.currentUser && this.currentUserShouldBeLinked()
				? linkWithRedirect(this.auth.currentUser, provider)
				: signInWithRedirect(this.auth, provider)
		).catch(error => this.processError(error));
	}

	private loginToFirebase(credential: AuthCredential): Promise<void> {
		return (
			this.auth.currentUser && this.currentUserShouldBeLinked()
				? linkWithCredential(this.auth.currentUser, credential)
				: signInWithCredential(this.auth, credential)
		)
			.then(() => Promise.resolve())
			.catch(error => this.processError(error));
	}

	private currentUserShouldBeLinked(): boolean {
		return false;
		// return (
		// 	this.currentLoggedInUser?.temporary &&
		// 	(!!this.currentLoggedInUser?.firstName ||
		// 		!!this.currentLoggedInUser?.lastName ||
		// 		!!this.currentLoggedInUser?.street ||
		// 		!!this.currentLoggedInUser?.houseNumber ||
		// 		!!this.currentLoggedInUser?.postalCode ||
		// 		!!this.currentLoggedInUser?.city ||
		// 		!!this.currentLoggedInUser?.email ||
		// 		!!this.currentLoggedInUser?.phone ||
		// 		!!this.currentLoggedInUser?.country)
		// );
	}

	private processUser(firebaseUser: FirebaseUser): Observable<UserTuple> {
		if (firebaseUser) {
			return this.userService
				.get(firebaseUser.uid)
				.pipe(
					switchMap(existingUser =>
						this.auth.currentUser
							? from(
									this.syncUserDBEntry(
										existingUser,
										this.auth.currentUser
									).then(userTuple =>
										this.processAuthToken(userTuple)
									)
							  )
							: of(null)
					)
				);
		} else {
			this.permissionsService.flushPermissions();
			this.setRedirectUrl(null);
			return of(null);
		}
	}

	private async syncUserDBEntry(
		existingUser: User,
		firebaseUser: FirebaseUser
	): Promise<UserTuple> {
		// If no existing user was found, it could also be a network problem, so check if Firstore is available first
		if (!existingUser) {
			const appVersion = await firstValueFrom(
				this.appVersionService.get()
			);
			if (!appVersion) {
				// The app version could not be loaded, this means Firestore is not accessible.
				// This could be the reason, why no user was found -> cancel user creation with an error.
				if (firebaseUser?.uid) {
					// If an user was loggedin, we are now on the login page. If this user logs in again, Firebase will
					// not trigger the AuthChanged handler again, since the user ID did not change and thus the user will
					// stay on the login page until a manual reload of the app.
					// If the heartbeat of TimeService will confirm, that there is no internet, a reload button will be
					// shown, which would relogin the user. But if the TimeService has internet, it will trigger a logout
					// to solve this issue.
					await this.errorHandlingService.handleFatalError(
						new Error('auth/network-request-failed')
					);
				} else {
					this.processError('auth/network-request-failed');
				}
				return Promise.reject(null);
			}
		}

		// Loggedin user already exists
		if (existingUser) {
			if (
				firebaseUser &&
				(firebaseUser.isAnonymous !== existingUser.temporary ||
					// If the app is running in two different Browsers after registration
					// on Browser will save verified to true and triggers the other Browser which still has a Firebase
					// user with verified set to false which will change the users verified flag back to false
					// -> endless loop
					(firebaseUser.emailVerified && !existingUser.emailVerified))
			) {
				// An anonymous user was linked to Google or Apple account, so remove the temporary flag
				existingUser.temporary = firebaseUser.isAnonymous;
				// Or the email has been verified
				existingUser.emailVerified = firebaseUser.emailVerified;

				await this.userService.update(existingUser);
			}

			// Check for user blocked expiration -> unblock if this is the case
			if (
				existingUser.blockedUntil &&
				!(await this.userService.isBlocked(existingUser))
			) {
				// This will change the user, so cancel processing here, since it will be triggered again by the user
				// change observer
				return this.userService
					.unblock(existingUser, null)
					.then(() => Promise.reject(null));
			}
			return Promise.resolve(new UserTuple(existingUser, firebaseUser));
		} // Loggedin user does  not exist
		else if (firebaseUser) {
			// Check if the user was loggedin, then he has been deleted by backend -> cancel processing and logout
			if (firebaseUser.uid === this.currentLoggedInUser?.id) {
				return this.logout().then(() => Promise.reject(null));
			}

			// Create the new user based on the provided data if an Identity Provider was used for login
			const [firstName, lastName] = (
				firebaseUser.displayName || this.lastNativeLoginDisplayName
			)?.split(' ', 2) ?? ['', ''];

			if (environment.clientLogEnabled) {
				console.log(`Create new user: ${lastName}, ${firstName}`);
			}
			return this.userService
				.create(
					firebaseUser.uid,
					firebaseUser.isAnonymous,
					firebaseUser.email ?? '',
					firebaseUser.emailVerified,
					firstName ?? '',
					lastName ?? ''
				)
				.then(u =>
					u
						? Promise.resolve(new UserTuple(u, firebaseUser))
						: Promise.reject(null)
				)
				.catch(() => Promise.reject(null));
		}
		return Promise.reject(null);
	}

	private processAuthToken(userTuple: UserTuple): Promise<UserTuple> {
		if (!userTuple?.firebaseUser) {
			return Promise.resolve(userTuple);
		}

		// Force token refresh if the update timestamp of the old and the new loggedin user differs
		const forceRefresh =
			!!this.currentLoggedInUser?.id &&
			this.currentLoggedInUser.id === userTuple.user?.id &&
			this.currentLoggedInUser.tokenClaimsUpdatedAt?.toMillis() !==
				userTuple.user?.tokenClaimsUpdatedAt?.toMillis();

		return userTuple.firebaseUser
			.getIdTokenResult(forceRefresh)
			.then(tokenResult => {
				let permissionsList: string[] =
					(tokenResult?.claims?.permissions as string[]) ?? [];

				permissionsList = extendPermissions(permissionsList);

				this.permissionsService.loadPermissions(permissionsList);

				if (environment.clientLogEnabled) {
					console.log('Force token refresh: ', forceRefresh);
					console.log('Token claims: ', tokenResult.claims);
					console.log('User permissions: ', permissionsList);
				}

				this.tokenRefreshedSubject.next(true);
				return Promise.resolve(userTuple);
			});
	}

	private setCurrentUser(user: User, providerId: string): Promise<void> {
		const oldUserId = this.currentLoggedInUser?.id ?? null;

		this.currentLoggedInUser = user;
		this.currentLoggedInUserProviderId = providerId;
		this.loggedInUserSubject.next(user ?? null);
		if (oldUserId !== (user?.id ?? null)) {
			this.loggedInUserIdSubject.next(user?.id ?? null);
		}

		return this.userDefaultsService.resetOnLogin(user?.id ?? null);
	}

	private setRedirectUrl(url: string): void {
		if (url && /^\/?login(\/.*)?$/i.test(url)) {
			return;
		}
		if (url) {
			localStorage.setItem(AuthService.REDIRECT_URL_STORAGE_KEY, url);
		} else {
			localStorage.removeItem(AuthService.REDIRECT_URL_STORAGE_KEY);
		}
	}

	private getRedirectUrl(): string {
		return localStorage.getItem(AuthService.REDIRECT_URL_STORAGE_KEY);
	}

	private redirectAfterLogin(): void {
		const redirectUrl = this.getRedirectUrl();
		if (redirectUrl) {
			this.router.navigateByUrl(
				(redirectUrl.startsWith('/') ? '' : '/') + redirectUrl,
				{ replaceUrl: true }
			);
			this.setRedirectUrl(null);
		} else if (
			this.authStateChangeInProgress &&
			this.isLoginInProgress &&
			(this.isLoggedInWithPassword() || Capacitor.isNativePlatform())
		) {
			// Ensure a page reload after password based registration / login, so that the AuthGuard comes in place
			// for correct redirection.
			// Also on native devices after logout and relogin, no redirect is set, so leave login page and goto home.
			this.router.navigateByUrl('/', { replaceUrl: true });
		}
	}

	private setRegistrationRedirectUrl(url: string): void {
		if (url && /^\/?registration(\/.*)?$/i.test(url)) {
			return;
		}
		if (url) {
			localStorage.setItem(
				AuthService.REDIRECT_REGISTER_URL_STORAGE_KEY,
				url
			);
		} else {
			localStorage.removeItem(
				AuthService.REDIRECT_REGISTER_URL_STORAGE_KEY
			);
		}
	}

	private getRegistrationRedirectUrl(): string {
		return localStorage.getItem(
			AuthService.REDIRECT_REGISTER_URL_STORAGE_KEY
		);
	}

	private getUserProviderId(firebaseUser: FirebaseUser): string {
		return firebaseUser?.providerData &&
			firebaseUser?.providerData.length > 0
			? firebaseUser.providerData[0].providerId
			: null;
	}

	private reloginFor(firebaseUser: FirebaseUser): Promise<FirebaseUser> {
		const providerId = this.getUserProviderId(firebaseUser);
		if (providerId === AuthService.PROVIDER_ID_PASSWORD) {
			return Promise.resolve(firebaseUser);
		}
		return providerId
			? reauthenticateWithRedirect(
					firebaseUser,
					this.providerFor(providerId)
			  ).then(() => Promise.resolve(this.auth.currentUser))
			: Promise.reject(null); // Anonymous users cannot relogin
	}

	private async processError(
		error: any,
		navigateToErrorPage = false
	): Promise<void> {
		if (environment.clientLogEnabled) {
			console.error(error);
		}
		await this.notificationService.hideSpinner();
		return navigateToErrorPage
			? this.router.navigateByUrl('/error').then(() => Promise.resolve())
			: this.notificationService.showMessage(this.getErrorMessage(error));
	}

	private getErrorMessage(error: any): string {
		const code =
			error && Object.prototype.hasOwnProperty.call(error, 'code')
				? error.code
				: error &&
				  Object.prototype.hasOwnProperty.call(error, 'message')
				? error.message
				: error && typeof error === 'string'
				? error
				: '';
		switch (code) {
			case 'auth/user-not-found':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/wrong-password':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/email-already-in-use':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/credential-already-in-use':
				return 'auth.messages.accountAlreadyExistOrLinkedToAnotherAcc';
			case 'auth/account-exists-with-different-credential':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/app-deleted':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/app-not-authorized':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-email':
				return 'auth.messages.enteredEmailIsInvalid';
			case 'auth/argument-error':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/invalid-api-key':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-user-token':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-tenant-id':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/network-request-failed':
				return 'auth.messages.networkError';
			case 'auth/operation-not-allowed':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/operation-not-supported-in-this-environment':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/requires-recent-login':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/too-many-requests':
				return 'auth.messages.tooManyAttempts';
			case 'auth/unauthorized-domain':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/user-disabled':
				return 'auth.messages.accountDisabled';
			case 'auth/user-token-expired':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/web-storage-unsupported':
				return 'auth.messages.errorPleaseTryAgain';
			default:
				return Capacitor.isNativePlatform()
					? 'auth.messages.errorPleaseRestartApp'
					: 'auth.messages.errorPleaseReloadBrowser';
		}
	}
}
