import { TimestampUtils } from '@nova-core/time/timestamp.utils';
import { Observable, of, throwError } from 'rxjs';

import { User, UserDeviceToken } from './user';
import { UserInfo } from './user-info';
import { UserRepository } from './user-repository';
import { UserSettings } from './user-settings';

export class UserMockRepository implements UserRepository {
	private user: User[] = [
		new User(
			'1',
			'Paula',
			'Werner',
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			'de',
			TimestampUtils.DEFAULT_TIMEZONE,
			true,
			null,
			null,
			false,
			null,
			null,
			null,
			null,
			null
		),
		new User(
			'2',
			'Armin',
			'Wassermann',
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			'de',
			TimestampUtils.DEFAULT_TIMEZONE,
			false,
			null,
			null,
			false,
			null,
			null,
			null,
			null,
			null
		)
	];

	private UserSettings: UserSettings[] = [
		new UserSettings(
			'default',
			'selectedCompanyId1',
			'selectedLocationId1',
			'selectedEventId1'
		),
		new UserSettings(
			'default',
			'selectedCompanyId2',
			'selectedLocationId2',
			'SelectedEventId2'
		)
	];

	public single(id: string): Observable<User> {
		const user = this.user.find(gc => gc.id === id);
		return user ? of(user) : throwError(() => new Error('not found'));
	}

	public add(item: User): Promise<string> {
		return Promise.resolve(item.id);
	}

	public update(item: User): Promise<void> {
		return Promise.resolve();
	}

	public updateFaceRecognition(
		id: string,
		faceRecognitionPhoto?: string
	): Promise<void> {
		return Promise.resolve();
	}

	public updateDeviceTokens(
		id: string,
		deviceTokens: UserDeviceToken[],
		language?: string,
		timeZone?: string
	): Promise<void> {
		return Promise.resolve();
	}

	public updateLocaleData(
		id: string,
		language: string,
		timeZone: string
	): Promise<void> {
		return Promise.resolve();
	}

	public deleteUser(userId: string): Promise<{ isDeleted: boolean }> {
		return Promise.resolve({ isDeleted: true });
	}

	public enableFaceRecognitionFeature(id: string): Promise<void> {
		return Promise.resolve();
	}

	public getDefaultSettings(userId: string): Observable<UserSettings> {
		const UserSettings = this.UserSettings.find(gc => gc.id === 'default');
		return UserSettings
			? of(UserSettings)
			: throwError(() => new Error('not found'));
	}

	public setDefaultSettings(
		userId: string,
		item: UserSettings
	): Promise<string> {
		return Promise.resolve('default');
	}

	public getUserInfo(userId: string): Promise<UserInfo> {
		const user = this.user.find(gc => gc.id === userId);
		return Promise.resolve(user);
	}
}
