import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiClientModule } from '@nova-core/api-client/api-client.module';
import { AuthModule } from '@nova-core/auth/auth.module';
import { AuthService } from '@nova-core/auth/auth.service';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { TimeService } from '@nova-core/time/time.service';
import { environment } from '@nova-environments/environment';
import { QrScannerModule } from '@nova-features/qr-scanner/qr-scanner.module';
import { AppBannerModule } from '@nova-shared/app-banner/app-banner.module';
import { DirectivesModule } from '@nova-shared/directives/directives.module';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeDe, 'de');
class DynamicLocaleId extends String {
	constructor(protected service: TranslateService) {
		super('');
	}

	public toString(): string {
		return this.service.currentLang;
	}
}

const appInitializerFn =
	(
		i18nService: I18nService,
		timeService: TimeService,
		authService: AuthService
	) =>
	() => {
		return i18nService.initTranslate().then(() => {
			// Init server time (asynchronously)
			timeService.init();
			return authService.init();
		});
	};

const runtimeApplicationConfiguration = {
	provide: APP_INITIALIZER,
	useFactory: appInitializerFn,
	multi: true,
	deps: [I18nService, TimeService, AuthService]
};

export const createTranslateLoader = (
	http: HttpClient
): TranslateHttpLoader => {
	return new TranslateHttpLoader(
		http,
		'./assets/i18n/',
		`.json?v=${environment.version as string}`
	);
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		ApiClientModule,
		NgxPermissionsModule.forRoot(),
		AuthModule,
		ReactiveFormsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		IonicModule.forRoot({
			backButtonText: '',
			backButtonIcon: '/assets/icons/back.svg',
			backButtonDefaultHref: '/',
			_forceStatusbarPadding: environment.forceStatusbarPadding
		}),
		DirectivesModule,
		AppBannerModule,
		QrScannerModule
	],
	providers: [
		runtimeApplicationConfiguration,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: LOCALE_ID,
			deps: [TranslateService],
			useClass: DynamicLocaleId
		},
		Printer
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
