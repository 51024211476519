import { Timestamp } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';

import { Messaging } from './messaging';
import { MessagingRepository } from './messaging.repository';

export class MessagingMockRepository implements MessagingRepository {
	private testResults: Messaging[] = [
		new Messaging(
			'id',
			'companyId',
			'locationId',
			'eventId',
			Timestamp.fromDate(new Date()),
			'userId',
			null,
			'title',
			'title_en',
			'message',
			'message_en',
			null,
			null,
			null,
			false
		)
	];

	public listUnread(userId: string): Observable<Messaging[]> {
		return of(this.testResults);
	}

	public update(userId: string, messaging: any): Promise<void> {
		return Promise.resolve();
	}

	public updateIsRead(userId: string, messaging: Messaging): Promise<void> {
		return Promise.resolve();
	}

	public add(receiverId: string, messaging: Messaging): Promise<string> {
		return Promise.resolve('1');
	}

	public remove(userId: string, messagingId: string): Promise<boolean> {
		return Promise.resolve(true);
	}
}
