import { inject, InjectionToken } from '@angular/core';
import { ApiFirebaseClient } from '@nova-core/api-client/api-firebase-client.service';
import { environment } from '@nova-environments/environment';

import { FaceRecognitionFirebaseRepository } from './face-recognition-firebase-repository';
import { FaceRecognitionMockRepository } from './face-recognition-mock-repository';
import { FaceRecognitionPhoto } from './face-recognition-photo';
import { FaceRecognitionStoreResult } from './face-recognition-store-result';

export const FACE_RECOGNITION_REPOSITORY =
	new InjectionToken<FaceRecognitionRepository>('FaceRecognitionRepository', {
		providedIn: 'root',
		factory: () =>
			environment.useMockData
				? new FaceRecognitionMockRepository()
				: new FaceRecognitionFirebaseRepository(
						inject(ApiFirebaseClient)
				  )
	});

export interface FaceRecognitionRepository {
	validate(photo: FaceRecognitionPhoto): Promise<boolean>;

	store(
		userId: string,
		photo: FaceRecognitionPhoto,
		verifiedFalseHits: string[],
		accountToDelete?: string
	): Promise<FaceRecognitionStoreResult>;

	read(userId: string): Promise<FaceRecognitionPhoto>;

	delete(userId: string): Promise<boolean>;
}
