import { UserContactData } from '@nova-features/user/user-contact-data';

export class Permission {
	public static readonly FIREBASE_PERMISSION_ERROR =
		'Missing or insufficient permissions.';

	constructor(
		public id: string,
		public companyId: string | null,
		public locationId: string | null,
		public eventId: string | null,
		public userId: string,
		public permissions: PermissionType[],
		public userInfo: PermissionUserInfo
	) {}

	public static build(
		userContactData: UserContactData,
		permissions: PermissionType[],
		companyId: string,
		locationId?: string,
		eventId?: string
	): Permission {
		return new Permission(
			null,
			companyId,
			locationId ?? null,
			eventId ?? null,
			userContactData.id,
			permissions,
			{
				id: userContactData.id,
				firstName: userContactData.firstName,
				lastName: userContactData.lastName,
				email: userContactData.email
			} as PermissionUserInfo
		);
	}
}

export enum PermissionType {
	ASSISTANT = 'assistant',
	MANAGER = 'manager',
	ADMIN = 'admin',

	EVENT_ASSISTANT = 'eventAssistant',
	EVENT_MANAGER = 'eventManager',
	EVENT_ADMIN = 'eventAdmin',

	TESTSTATION_ASSISTANT = 'testStationAssistant',
	TESTSTATION_MANAGER = 'testStationManager',
	TESTSTATION_ADMIN = 'testStationAdmin',

	HOTEL_ASSISTANT = 'hotelAssistant',
	HOTEL_MANAGER = 'hotelManager',
	HOTEL_ADMIN = 'hotelAdmin',

	KIOSK_MONITORING = 'kioskMonitoring',
	PRESENCE_ADMIN = 'presenceAdmin',

	FACERECOGNITION_FEATURE = 'faceRecognitionFeature',
	OPERATOR_FEATURE = 'operatorFeature',
	UNBLOCK_FEATURE = 'unblockFeature'
}

export enum PermissionGroupType {
	OPERATOR = 'operator',
	EVENT = 'event',
	TEST_STATION = 'testStation',
	HOTEL = 'hotel',
	OTHERS = 'others'
}

export type PermissionUserInfo = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
};

export const testPermissionForEvent = [].concat([
	PermissionType.EVENT_ASSISTANT,
	PermissionType.EVENT_MANAGER,
	PermissionType.EVENT_ADMIN
]);

export const buildLocationPerm = (
	locationId: string,
	permission: PermissionType
): string => {
	return `l/${locationId}/${permission}`;
};

export const buildCompanyPerm = (
	companyId: string,
	permission: PermissionType
): string => {
	return `c/${companyId}/${permission}`;
};

export const buildEventPerm = (
	eventId: string,
	permission: PermissionType
): string => {
	return `e/${eventId}/${permission}`;
};

export const extendPermissions = (permissionsList: string[]): string[] => {
	let newPermissions = [];

	permissionsList.forEach(perm => {
		if (perm === PermissionType.PRESENCE_ADMIN) {
			return newPermissions.push(perm);
		}

		if (perm.indexOf('Admin') !== -1) {
			newPermissions.push(perm.split('Admin').join('Manager'));
			newPermissions.push(perm.split('Admin').join('Assistant'));
		}

		if (perm.indexOf('admin') !== -1) {
			newPermissions.push(perm.split('admin').join('manager'));
			newPermissions.push(perm.split('admin').join('assistant'));
		}

		if (perm.indexOf('Manager') !== -1) {
			newPermissions.push(perm.split('Manager').join('Assistant'));
		}

		if (perm.indexOf('manager') !== -1) {
			newPermissions.push(perm.split('manager').join('assistant'));
		}

		newPermissions.push(perm);
	});

	return newPermissions;
};
