import { Browser } from '../device-permission.types';

export const cameraAlertTexts: Array<{
	browser: Browser;
	subHeader: string;
	message: string;
}> = [
	{
		browser: 'edge',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.cameraAlert.edge.message'
	},
	{
		browser: 'opera',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.cameraAlert.opera.message'
	},
	{
		browser: 'chrome',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.cameraAlert.chrome.message'
	},
	{
		browser: 'ie',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.cameraAlert.ie.message'
	},
	{
		browser: 'firefox',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.cameraAlert.firefox.message'
	},
	{
		browser: 'safari',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.cameraAlert.safari.message'
	},
	{
		browser: 'other',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.cameraAlert.other.message'
	}
];
