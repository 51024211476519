import { Browser } from '../device-permission.types';

export const cameraAlertTextsIOS: Array<{
	browser: Browser;
	subHeader: string;
	message: string;
}> = [
	{
		browser: 'edge',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.checkSettingsWhetherActivatedCamera'
	},
	{
		browser: 'opera',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.checkSettingsWhetherActivatedCamera'
	},
	{
		browser: 'chrome',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.checkSettingsWhetherActivatedCamera'
	},
	{
		browser: 'ie',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.checkSettingsWhetherActivatedCamera'
	},
	{
		browser: 'firefox',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.checkSettingsWhetherActivatedCamera'
	},
	{
		browser: 'safari',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.checkSettingsWhetherActivatedCamera'
	},
	{
		browser: 'other',
		subHeader: 'devicePermission.howToSwitchOnTheCamera',
		message: 'devicePermission.checkSettingsWhetherActivatedCamera'
	}
];
