import { FaceRecognitionPhoto } from './face-recognition-photo';
import { FaceRecognitionRepository } from './face-recognition-repository';
import { FaceRecognitionStoreResult } from './face-recognition-store-result';

export class FaceRecognitionMockRepository
	implements FaceRecognitionRepository
{
	public validate(photo: FaceRecognitionPhoto): Promise<boolean> {
		return Promise.resolve(true);
	}

	public store(
		userId: string,
		photo: FaceRecognitionPhoto,
		verifiedFalseHits: string[],
		accountToDelete?: string
	): Promise<FaceRecognitionStoreResult> {
		return Promise.resolve({
			success: true,
			reference: userId,
			foundDuplicates: []
		} as FaceRecognitionStoreResult);
	}

	public read(userId: string): Promise<FaceRecognitionPhoto> {
		return Promise.resolve({
			base64Data: 'base64:/123',
			format: 'images/jpeg'
		} as FaceRecognitionPhoto);
	}

	public delete(userId: string): Promise<boolean> {
		return Promise.resolve(true);
	}
}
