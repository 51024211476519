import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterWrapperComponent } from '@nova-shared/layout-components/footer-wrapper/footer-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';

import { CircleCardComponent } from './circle-card/circle-card.component';
import { OrDividerComponent } from './or-divider/or-divider.component';
import { TemperatureComponent } from './temperature/temperature.component';

@NgModule({
	declarations: [
		CircleCardComponent,
		FooterWrapperComponent,
		TemperatureComponent,
		OrDividerComponent
	],
	imports: [CommonModule, IonicModule, TranslateModule],
	exports: [
		CircleCardComponent,
		FooterWrapperComponent,
		TemperatureComponent,
		OrDividerComponent
	]
})
export class LayoutComponentsModule {}
