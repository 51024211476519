import { inject, InjectionToken } from '@angular/core';
import { ApiFirestoreClient } from '@nova-core/api-client/api-firestore-client.service';
import { environment } from '@nova-environments/environment';
import { Observable } from 'rxjs';

import { AppVersion } from './app-version';
import { AppVersionFirestoreRepository } from './app-version-firestore-repository';
import { AppVersionMockRepository } from './app-version-mock-repository';

export const APP_VERSION_REPOSITORY = new InjectionToken<AppVersionRepository>(
	'AppVersionsRepository',
	{
		factory: () =>
			environment.useMockData
				? new AppVersionMockRepository()
				: new AppVersionFirestoreRepository(inject(ApiFirestoreClient))
	}
);

export interface AppVersionRepository {
	single(): Observable<AppVersion>;
}
