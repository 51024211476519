import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@nova-environments/environment';
import { Observable, ReplaySubject } from 'rxjs';

export type ThemeMode = 'light' | 'dark';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {
	private static readonly THEME_FILES = ['variables.css', 'global.css'];
	private static readonly TENANT_THEME_BASE_PATH = '/assets/tenant/';

	public theme: Observable<string>;
	public themeMode: Observable<ThemeMode>;

	private themeSubject = new ReplaySubject<string>(1);
	private currentTheme = null;
	private visitorTheme: string;
	private operatorTheme: string;
	private themeModeSubject = new ReplaySubject<ThemeMode>(1);

	constructor(@Inject(DOCUMENT) private doc: Document) {
		this.theme = this.themeSubject.asObservable();
		this.themeMode = this.themeModeSubject.asObservable();

		// Check for development flag which forces the dark mode to en-/disabled
		if (environment.forceThemeMode) {
			this.themeModeSubject.next(
				environment.forceThemeMode === 'light'
					? 'light'
					: environment.forceThemeMode === 'dark'
					? 'dark'
					: null
			);
			return;
		}

		const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
		this.themeModeSubject.next(prefersDark.matches ? 'dark' : 'light');

		// Listen for changes to the selected mode
		prefersDark.onchange = mediaQuery =>
			this.themeModeSubject.next(mediaQuery.matches ? 'dark' : 'light');
	}

	public setVisitorTheme(theme: string): void {
		this.setTheme(theme);
		this.visitorTheme = theme ?? null;
	}

	public setToVisitorTheme(): void {
		this.setTheme(this.visitorTheme);
	}

	public setOperatorTheme(theme: string): void {
		this.setTheme(theme);
		this.operatorTheme = theme ?? null;
	}

	public setToOperatorTheme(): void {
		this.setTheme(this.operatorTheme);
	}

	public setTemporaryTheme(theme: string): void {
		this.setTheme(theme);
	}

	private setTheme(theme: string): void {
		if ((theme ?? null) !== this.currentTheme) {
			if (environment.clientLogEnabled) {
				console.log(`Switch theme to: ${theme ?? null}`);
			}
			if (theme) {
				this.loadTheme(theme);
			} else {
				this.removeLoadedTheme();
			}
			this.currentTheme = theme ?? null;
			this.themeSubject.next(theme ?? null);
		}
	}

	private loadTheme(theme: string): void {
		ThemeService.THEME_FILES.forEach(themeFile => {
			this.loadThemeFile(theme, themeFile);
		});
	}

	private removeLoadedTheme(): void {
		ThemeService.THEME_FILES.forEach(themeFile => {
			this.doc.getElementById(themeFile)?.remove();
		});
	}

	private loadThemeFile(theme: string, cssFileName: string): void {
		const themeId = cssFileName;
		const themePath = `${theme}-${cssFileName}`;

		this.doc.getElementById(themeId)?.remove();

		const link: HTMLLinkElement = this.doc.createElement('link');
		link.id = themeId;
		link.type = 'text/css';
		link.rel = 'stylesheet';
		link.href = themePath;
		this.doc.head.appendChild(link);
	}
}
