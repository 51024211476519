import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed as LocalActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import {
    ActionPerformed,
    DeliveredNotifications,
    PushNotifications,
    PushNotificationSchema,
    Token,
} from '@capacitor/push-notifications';
import { AuthService } from '@nova-core/auth/auth.service';
import { environment } from '@nova-environments/environment';
import { Messaging, MessagingAction } from '@nova-features/messaging/messaging';
import { MessagingController } from '@nova-features/messaging/messaging.controller';
import { UserDefaultsService } from '@nova-features/user/user-defaults.service';
import { UserService } from '@nova-features/user/user.service';
import { DevicePermissionService } from '@nova-shared/device-permission/device-permission.service';
import { NotificationService } from '@nova-shared/notification.service';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FcmService {
	public static readonly GROUP_CHECK_OUT = 'CHECK_OUT';
	private static deviceToken: string;

	public currentDeviceToken: Observable<string>;

	private currentDeviceTokenSubject = new ReplaySubject<string | null>(1);
	private isInited = false;
	private localNotificationId = 1;

	constructor(
		private userService: UserService,
		private authService: AuthService,
		private notificationService: NotificationService,
		private devicePermissionService: DevicePermissionService,
		private messagingController: MessagingController,
		private userDefaultsService: UserDefaultsService,
		private router: Router
	) {
		this.currentDeviceToken = this.currentDeviceTokenSubject.asObservable();
		this.currentDeviceTokenSubject.next(null);
	}

	public static isRegistered(): boolean {
		return !!FcmService.deviceToken;
	}

	public async registerWithConfirmation(
		loggedInUserId: string,
		confirmMessageKey?: string,
		deniedMessageKey?: string,
		skipRefresh = true,
		forcePrompt = false
	): Promise<boolean | null> {
		const askUserForMessages =
			forcePrompt ||
			!loggedInUserId ||
			(await this.userDefaultsService.getUserIdAskedForFcm()) !==
				loggedInUserId;
		return this.register(
			askUserForMessages,
			confirmMessageKey,
			deniedMessageKey,
			skipRefresh,
			forcePrompt
		).then(activated => {
			// User do not want Push, so do not ask same user again
			if (activated === false && loggedInUserId) {
				this.userDefaultsService.setUserIdAskedForFcm(loggedInUserId);
			}
			return Promise.resolve(activated);
		});
	}

	public async register(
		openPrompt = true,
		confirmMessageKey?: string,
		deniedMessageKey?: string,
		skipRefresh = false,
		forcePrompt = false
	): Promise<boolean | null> {
		if (!Capacitor.isNativePlatform()) {
			return Promise.resolve(null);
		}

		if (!this.isInited) {
			this.initListeners();
		}

		let permissionStatus = await PushNotifications.checkPermissions();

		if (permissionStatus.receive === 'prompt' && openPrompt) {
			const confirm = await this.notificationService.showConfirm(
				'fcm.requestPermissions',
				confirmMessageKey ?? 'fcm.requestPermissionsConfirm'
			);
			if (!confirm) {
				return Promise.resolve(false);
			}
			permissionStatus = await PushNotifications.requestPermissions();
		} else if (
			permissionStatus.receive !== 'prompt' &&
			permissionStatus.receive !== 'granted' &&
			forcePrompt
		) {
			await this.devicePermissionService.showSettingsAlert(
				'fcm.requestPermissions',
				null,
				deniedMessageKey ?? 'fcm.deniedPermissionsMessage'
			);
			return Promise.resolve(null);
		} else if (skipRefresh) {
			return Promise.resolve(null);
		}

		if (permissionStatus.receive !== 'granted') {
			return Promise.resolve(false);
		}

		return PushNotifications.register().then(() => Promise.resolve(true));
	}

	public sendLocalPush(
		title: string,
		body: string,
		group?: string,
		data?: any
	): Promise<void> {
		return LocalNotifications.schedule({
			notifications: [
				{
					id: this.localNotificationId++,
					title,
					body,
					threadIdentifier: group,
					actionTypeId: group,
					extra: {
						data
					}
				}
			]
		}).then();
	}

	public getDeliveredNotifications(): Promise<DeliveredNotifications> {
		if (!Capacitor.isNativePlatform()) {
			return Promise.resolve(null);
		}
		return PushNotifications.getDeliveredNotifications();
	}

	public removeDeliveredNotifications(
		notifications: DeliveredNotifications
	): Promise<void> {
		if (!Capacitor.isNativePlatform() || !notifications) {
			return Promise.resolve();
		}
		return PushNotifications.removeDeliveredNotifications(notifications);
	}

	public async removeDeliveredNotificationsByTitles(
		titles: string[]
	): Promise<void> {
		const notifications = await this.getDeliveredNotifications();
		if (!notifications) {
			return Promise.resolve();
		}
		notifications.notifications = notifications.notifications?.filter(
			n => !titles || titles.includes(n.title)
		);
		if (!notifications.notifications?.length) {
			return Promise.resolve();
		}
		return this.removeDeliveredNotifications(notifications);
	}

	public removeAllDeliveredNotifications(): Promise<void> {
		if (!Capacitor.isNativePlatform()) {
			return Promise.resolve();
		}
		return PushNotifications.removeAllDeliveredNotifications();
	}

	private initListeners(): void {
		this.isInited = true;

		// Add token for the user or refresh its timestamp
		PushNotifications.addListener('registration', (token: Token) => {
			if (environment.clientLogEnabled) {
				console.log(
					`Push registration successful with token: ${token.value}`
				);
			}

			FcmService.deviceToken = token.value;
			this.userDefaultsService.setDeviceToken(FcmService.deviceToken);
			this.currentDeviceTokenSubject.next(FcmService.deviceToken);

			const user = this.authService.getCurrentLoggedInUser();
			if (user) {
				this.userService.addDeviceToken(user, FcmService.deviceToken);
			}
		});
		PushNotifications.addListener('registrationError', (error: any) => {
			this.notificationService.showError(error);
		});

		PushNotifications.addListener(
			'pushNotificationReceived',
			(notification: PushNotificationSchema) => {
				if (notification.data?.deliverPushWhenOpen === 'true') {
					// Deliver remote push as local push, if enabled
					this.sendLocalPush(
						notification.title,
						notification.body,
						notification.data?.group as string,
						notification.data
					);
				}
				if (notification.data?.showInternalWhenOpen === 'true') {
					// Show remote push in messaging alert, if enabled
					const message = this.buildMessagingFromPush(notification);
					this.messagingController.presentMessage(message);
				}
			}
		);
		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			(notificationAction: ActionPerformed) =>
				this.processLink(
					notificationAction.notification.data?.link as string
				)
		);

		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(notificationAction: LocalActionPerformed) => {
				const actions: { action: string; title: string }[] = JSON.parse(
					(notificationAction.notification.extra?.data
						?.actions as string) ?? '[]'
				);
				switch (notificationAction.notification.actionTypeId) {
					case FcmService.GROUP_CHECK_OUT:
						this.processLink(
							actions[+notificationAction.actionId]?.action
						);
						break;
					default:
						this.processLink(
							notificationAction.notification.extra?.data
								?.link as string
						);
				}
			}
		);
		this.authService.loggedInUserId.subscribe(() => {
			const user = this.authService.getCurrentLoggedInUser();
			LocalNotifications.registerActionTypes({
				types: [
					{
						id: FcmService.GROUP_CHECK_OUT,
						actions: [
							{
								id: '0',
								title:
									user.language === 'en'
										? 'Check-out now'
										: 'Jetzt auschecken'
							}
						]
					}
				]
			});
		});
	}

	private processLink(link: string): void {
		const allowedUrl = `https://${environment.allowedAppLinkingHosts[0]}`;
		if (link?.startsWith(allowedUrl)) {
			this.router.navigateByUrl(link.substring(allowedUrl.length), {
				replaceUrl: true
			});
		}
	}

	private buildMessagingFromPush(
		notification: PushNotificationSchema
	): Messaging {
		const userId = null;
		const title = notification.title;
		const message = notification.body;
		const actions = notification.data?.actions
			? JSON.parse(notification.data?.actions as string).map(
					(action: { title: string; action: string }) =>
						({
							label: action.title,
							label_en: null,
							link: action.action
						} as MessagingAction)
			  )
			: [];
		return Messaging.build(
			null,
			null,
			null,
			userId,
			title,
			null,
			message,
			null,
			null,
			null,
			actions
		);
	}
}
