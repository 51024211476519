import { inject, InjectionToken } from '@angular/core';
import { ApiFirestoreClient } from '@nova-core/api-client/api-firestore-client.service';
import { environment } from '@nova-environments/environment';
import { Observable } from 'rxjs';

import { Messaging } from './messaging';
import { MessagingFirestoreRepository } from './messaging-firestore-repository';
import { MessagingMockRepository } from './messaging-mock-repository';

export const MESSAGING_REPOSITORY = new InjectionToken<MessagingRepository>(
	'MessagingRepository',
	{
		factory: () =>
			environment.useMockData
				? new MessagingMockRepository()
				: new MessagingFirestoreRepository(inject(ApiFirestoreClient))
	}
);

export interface MessagingRepository {
	listUnread(userId: string): Observable<Messaging[]>;

	update(userId: string, messaging: Messaging): Promise<void>;

	updateIsRead(userId: string, messaging: Messaging): Promise<void>;

	add(receiverId: string, messaging: Messaging): Promise<string>;

	remove(userId: string, messagingId: string): Promise<boolean>;
}
