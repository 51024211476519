import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { IonicSlides } from '@ionic/angular';
import { Observable } from 'rxjs';
import SwiperCore, { Navigation, Pagination, SwiperOptions, Zoom } from 'swiper';

import { Photo } from '../photo';

SwiperCore.use([Pagination, Navigation, Zoom, IonicSlides]);

@Component({
	selector: 'app-lightbox',
	templateUrl: './lightbox.component.html',
	styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnChanges, OnDestroy {
	@Input()
	public photos: (Photo | string | Promise<Photo> | Observable<Photo>)[];
	@Input()
	public imgSrc: string | SafeResourceUrl; // Provide image src directly (e.g. used by photo component)
	@Input()
	public initialSlide = 0;

	public isLoadingLightbox = true;
	public isLightboxOpened = false;
	public lightboxSwiperConfig: SwiperOptions;

	constructor() {}

	public ngOnChanges(): void {
		this.lightboxSwiperConfig = {
			initialSlide: this.initialSlide,
			slidesPerView: 1,
			spaceBetween: 5,
			speed: 300,
			effect: 'slide',
			loop: this.photos?.length > 1,
			centeredSlides: true,
			pagination:
				this.photos?.length > 1
					? {
							type: 'bullets',
							clickable: !Capacitor.isNativePlatform()
					  }
					: false,
			zoom: { maxRatio: 4, toggle: true },
			navigation: this.photos?.length > 1 && !Capacitor.isNativePlatform()
		};
	}

	public ngOnDestroy(): void {
		this.hide();
	}

	public show(initialSlide?: number): void {
		if (!this.isLightboxOpened && (this.photos?.length || this.imgSrc)) {
			if (initialSlide !== undefined) {
				this.lightboxSwiperConfig.initialSlide = initialSlide;
			}
			this.isLoadingLightbox = true;
			this.isLightboxOpened = true;
		}
	}

	public hide(): void {
		if (this.isLightboxOpened) {
			this.isLightboxOpened = false;
		}
	}

	public onLightboxImageDidLoad(): void {
		this.isLoadingLightbox = false;
	}
}
