export class UserSettings {
	constructor(
		public id: 'default',
		public selectedCompanyId: string,
		public selectedLocationId: string | null,
		public selectedEventId: string | null
	) {}

	public static build(
		selectedCompanyId: string,
		selectedLocationId?: string,
		selectedEventId?: string
	): UserSettings {
		return new UserSettings(
			'default',
			selectedCompanyId,
			selectedLocationId ?? null,
			selectedEventId ?? null
		);
	}
}
