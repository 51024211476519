import { Timestamp } from '@angular/fire/firestore';
import moment from 'moment-timezone';

export class TimestampUtils {
	public static DEFAULT_TIMEZONE = 'Europe/Berlin';

	public static listTimeZones(): string[] {
		return moment.tz.names();
	}

	public static getTimeZone(): string {
		return moment.tz.guess() ?? TimestampUtils.DEFAULT_TIMEZONE;
	}

	public static hasTime(
		timestamp: Timestamp | moment.Moment | null
	): boolean {
		const date = (
			timestamp instanceof Timestamp
				? timestamp
				: TimestampUtils.toTimestamp(timestamp)
		)?.toDate();
		return (
			date &&
			(date.getUTCHours() !== 0 ||
				date.getUTCMinutes() !== 0 ||
				date.getUTCSeconds() !== 0)
		);
	}

	public static clearTime(
		timestamp: Timestamp | moment.Moment | null
	): Timestamp | null {
		const keepLocalTime = true;
		const m =
			timestamp instanceof Timestamp
				? TimestampUtils.toMoment(timestamp)
				: timestamp;
		return m
			? TimestampUtils.toTimestamp(
					m
						.utcOffset(0, keepLocalTime)
						.set('hours', 0)
						.set('minutes', 0)
						.set('seconds', 0)
						.set('milliseconds', 0)
			  )
			: null;
	}

	public static clearSeconds(
		timestamp: Timestamp | moment.Moment | null
	): Timestamp | null {
		const m =
			timestamp instanceof Timestamp
				? TimestampUtils.toMoment(timestamp)
				: timestamp;
		return m
			? TimestampUtils.toTimestamp(
					m.set('seconds', 0).set('milliseconds', 0)
			  )
			: null;
	}

	public static unclearTime(
		timestamp: Timestamp | moment.Moment | null
	): Timestamp | null {
		const m =
			timestamp instanceof Timestamp
				? TimestampUtils.toMoment(timestamp)
				: timestamp;

		return m
			? TimestampUtils.toTimestamp(m.subtract(m.utcOffset(), 'minutes'))
			: null;
	}

	public static isDayEqual(
		from: Timestamp | null,
		to: Timestamp | null
	): boolean {
		if (!from || !to) {
			return false;
		}

		const fromDate = from?.toDate();
		const toDate = to?.toDate();

		return (
			fromDate &&
			toDate &&
			fromDate.getUTCDate() === toDate.getUTCDate() &&
			fromDate.getUTCMonth() === toDate.getUTCMonth() &&
			fromDate.getUTCFullYear() === toDate.getUTCFullYear()
		);
	}

	public static isMonthEqual(
		from: Timestamp | null,
		to: Timestamp | null
	): boolean {
		if (!from || !to) {
			return false;
		}

		const fromDate = from?.toDate();
		const toDate = to?.toDate();

		return (
			fromDate &&
			toDate &&
			fromDate.getUTCMonth() === toDate.getUTCMonth() &&
			fromDate.getUTCFullYear() === toDate.getUTCFullYear()
		);
	}

	public static isCurrentYear(
		timestamp: Timestamp | null,
		now: Timestamp
	): boolean {
		const date = timestamp?.toDate();
		return date && date.getUTCFullYear() === now.toDate().getUTCFullYear();
	}

	public static toMoment(value: Timestamp | null): moment.Moment | null {
		return value ? moment(value.seconds, 'X') : null;
	}

	public static toTimestamp(value: moment.Moment | null): Timestamp | null {
		return value ? new Timestamp(+value.format('X'), 0) : null;
	}

	/**
	 * If "separateTime" is "null", the time will be removed from the parsed date via the clearTime method.
	 */
	public static fromFormField(
		value: string | Timestamp,
		separateTime?: string | Timestamp | null
	): Timestamp | null {
		try {
			let date = TimestampUtils.parseDate(value);
			if (date === null && typeof value === 'string') {
				const parts =
					/([0-9]{1,2})([.-/])([0-9]{1,2})[.-/]([0-9]{2,4})/.exec(
						value
					);
				if (parts) {
					const separator = parts[2];
					let format =
						separator === '.'
							? `D${parts[1].length > 1 ? 'D' : ''}${separator}M${
									parts[3].length > 1 ? 'M' : ''
							  }`
							: `M${parts[1].length > 1 ? 'M' : ''}${separator}D${
									parts[3].length > 1 ? 'D' : ''
							  }`;
					format = format.concat(
						`${separator}YY${parts[4].length > 2 ? 'YY' : ''}`
					);
					date = TimestampUtils.parseDate(value, format);
				}
			}

			if (date && separateTime) {
				const time =
					typeof separateTime === 'string'
						? moment(separateTime)
						: TimestampUtils.toMoment(separateTime);
				if (time) {
					date = TimestampUtils.toMoment(
						TimestampUtils.unclearTime(date)
					);
					date.set('hours', time.hours()).set(
						'minutes',
						time.minutes()
					);
				}
			} else if (date && separateTime === null) {
				date = TimestampUtils.toMoment(TimestampUtils.clearTime(date));
			}
			if (date) {
				date.set('seconds', 0).set('milliseconds', 0);
			}

			return TimestampUtils.toTimestamp(date);
		} catch (e) {
			return null;
		}
	}

	public static toFormField(
		value: Timestamp | null,
		hasTime = true
	): string | undefined {
		const m = TimestampUtils.toMoment(value);
		return hasTime
			? m?.toISOString(true)
			: m?.utcOffset(0, false)?.format('YYYY-MM-DD');
	}

	public static buildMinFormField(
		serverTime: moment.Moment,
		currentValue: Timestamp = null,
		decYears: number = 0,
		decMonth: number = 0,
		decDays: number = 0
	): string {
		return TimestampUtils.buildFormField(
			serverTime,
			currentValue,
			-1 * decYears,
			-1 * decMonth,
			-1 * decDays,
			true
		);
	}

	public static buildMaxFormField(
		serverTime: moment.Moment,
		currentValue: Timestamp = null,
		incYears: number = 0,
		incMonth: number = 0,
		incDays: number = 0
	): string {
		return TimestampUtils.buildFormField(
			serverTime,
			currentValue,
			incYears,
			incMonth,
			incDays,
			false
		);
	}

	private static parseDate(
		value: string | Timestamp,
		format?: string
	): moment.Moment | null {
		const date = value
			? typeof value === 'string'
				? moment(value, format, true)
				: TimestampUtils.toMoment(value)
			: null;
		return date.isValid() ? date : null;
	}

	private static buildFormField(
		serverTime: moment.Moment,
		currentValue: Timestamp = null,
		incYears: number = 0,
		incMonth: number = 0,
		incDays: number = 0,
		forMin = false
	): string {
		let date = serverTime
			.clone()
			.add(incYears, 'years')
			.add(incMonth, 'months')
			.add(incDays, 'days');

		if (currentValue) {
			const currentMoment = TimestampUtils.toMoment(currentValue)
				.add(incYears, 'years')
				.add(incMonth, 'months')
				.add(incDays, 'days');
			if (
				(forMin && date.isAfter(currentMoment)) ||
				(!forMin && date.isBefore(currentMoment))
			) {
				date = currentMoment;
			}
		}

		return date.format('YYYY-MM-DD');
	}
}
