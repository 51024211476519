<div class="appbanner" [class.open]="show">
	<ion-button
		color="primary"
		(click)="close()"
		shape="round"
		size="small"
		class="close"
	>
		<ion-icon icon="close" size="small" slot="icon-only"></ion-icon
	></ion-button>
	<div
		class="icon"
		[ngStyle]="{ 'background-image': 'url(' + iconUrl + ')' }"
	></div>
	<ion-label class="ion-margin title">{{
		settings.title | translate
	}}</ion-label>
	<ion-button (click)="openStore()" size="small" class="app-link">
		{{ settings.buttonLabel | translate }}
	</ion-button>
</div>
