import { Constants } from './version';

export const environment = {
	version: Constants.version,
	production: true,
	allowedAppLinkingHosts: ['qa.nova.labs.rle.de'],
	appStoreAppUrl: 'https://apps.apple.com/app/nova-qa/id1559466841',
	playStoreAppUrl:
		'https://play.google.com/store/apps/details?id=com.rle.nova.qa',
	firebase: {
		apiKey: 'AIzaSyCGdnm_8GpfO64JJnVjOUsBnVkAiGNVxRc',
		authDomain: 'signin.qa.nova.labs.rle.de',
		projectId: 'rle-dsv-hygiene-alliance-qa',
		storageBucket: 'rle-dsv-hygiene-alliance-qa.appspot.com',
		messagingSenderId: '86900416340',
		appId: '1:86900416340:web:a71e62fc565ee0dccf72c1'
	},
	featureToggles: {},
	clientLogEnabled: true,
	useAuthEmulator: false,
	useDBEmulator: false,
	useStorageEmulator: false,
	useMockData: false,
	useFunctionsEmulator: false,
	forceThemeMode: null,
	forceStatusbarPadding: false
};
