import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
	selector: '[appPreventDoubleClick]'
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
	@Input()
	public blockDurationMills = 1000;

	@Output()
	public singleClick = new EventEmitter();

	private clicks = new Subject();
	private clicksSubscription: Subscription;

	constructor() {}

	ngOnInit() {
		this.clicksSubscription = this.clicks
			.pipe(throttleTime(this.blockDurationMills))
			.subscribe(e => this.emitThrottledClick(e));
	}

	emitThrottledClick(e) {
		this.singleClick.emit(e);
	}

	ngOnDestroy() {
		this.clicksSubscription?.unsubscribe();
	}

	@HostListener('click', ['$event'])
	public clickEvent(event) {
		event?.preventDefault();
		event?.stopPropagation();
		this.clicks.next(event);
	}
}
