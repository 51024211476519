import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppVersion } from './app-version';
import {
	APP_VERSION_REPOSITORY,
	AppVersionRepository
} from './app-version.repository';

@Injectable({
	providedIn: 'root'
})
export class AppVersionService {
	constructor(
		@Inject(APP_VERSION_REPOSITORY)
		private readonly repository: AppVersionRepository
	) {}

	public get(): Observable<AppVersion> {
		return this.repository.single();
	}
}
