import { Inject, Injectable } from '@angular/core';
import { serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { TimeService } from '@nova-core/time/time.service';
import { TimestampUtils } from '@nova-core/time/timestamp.utils';
import { environment } from '@nova-environments/environment';
import { Observable } from 'rxjs';

import { User, UserDeviceToken } from './user';
import { UserInfo } from './user-info';
import { USER_REPOSITORY, UserRepository } from './user-repository';
import { UserSettings } from './user-settings';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(
		@Inject(USER_REPOSITORY)
		private readonly repository: UserRepository,
		private timeService: TimeService,
		private i18nService: I18nService
	) {}

	public get(id: string): Observable<User> {
		return this.repository.single(id);
	}

	public createTemporary(user: User): Promise<User> {
		user.temporary = true;
		user.phoneVerified = false;
		user.emailVerified = false;
		user.timestamp = serverTimestamp();
		return this.repository.add(user).then(id => {
			return { ...user, id };
		});
	}

	public create(
		id: string,
		temporary: boolean,
		email: string,
		emailVerified: boolean,
		firstName: string,
		lastName: string
	): Promise<User> {
		const user = User.build(
			id,
			temporary,
			this.i18nService.getCurrentLanguage(),
			email,
			emailVerified,
			firstName,
			lastName
		);
		return this.repository.add(user).then(() => user);
	}

	public update(item: User): Promise<void> {
		return this.repository.update(item);
	}

	public updateFaceRecognition(
		id: string,
		faceRecognitionPhoto?: string
	): Promise<void> {
		return this.repository.updateFaceRecognition(id, faceRecognitionPhoto);
	}

	public async addDeviceToken(user: User, token: string): Promise<boolean> {
		if (!user || !token) {
			return Promise.resolve(false);
		}

		const now = await this.timeService.serverTimestampAsPromise();

		// Update the token max. once a day if it was already added
		const lastTokenUpdate: Timestamp =
			(user.deviceTokens?.find(t => t.token === token)
				?.timestamp as Timestamp) ?? null;
		const isTokenUpdateRequired =
			!lastTokenUpdate ||
			now.seconds - lastTokenUpdate.seconds > 3600 * 24;

		// Also update user locale data if changed (combine database update to save transactions)
		const language = this.i18nService.getCurrentLanguage();
		const timeZone = TimestampUtils.getTimeZone();

		if (
			isTokenUpdateRequired ||
			user.language !== language ||
			user.timeZone !== timeZone
		) {
			const deviceTokens =
				user.deviceTokens?.filter(t => t.token !== token) ?? [];
			deviceTokens.unshift({
				token,
				timestamp: now
			} as UserDeviceToken);

			if (environment.clientLogEnabled) {
				console.log('Update user device tokens to: ', deviceTokens);
			}

			return this.repository
				.updateDeviceTokens(user.id, deviceTokens, language, timeZone)
				.then(() => Promise.resolve(true));
		}

		return Promise.resolve(false);
	}

	public async updateLocaleData(user: User): Promise<void> {
		if (!user) {
			return Promise.resolve();
		}

		const language = this.i18nService.getCurrentLanguage();
		const timeZone = TimestampUtils.getTimeZone();
		if (user.language === language && user.timeZone === timeZone) {
			return Promise.resolve();
		}

		if (environment.clientLogEnabled) {
			console.log(
				`Update user language / timeZone to: ${language} / ${timeZone}`
			);
		}

		return this.repository.updateLocaleData(user.id, language, timeZone);
	}

	public async block(item: User): Promise<User> {
		// Do not use the loggedin user directly, if an error occurres
		const updateUser = { ...item };
		updateUser.blockedUntil = TimestampUtils.toTimestamp(
			(await this.timeService.serverDateToMoment()).add(
				User.BLOCK_EXPIRATION_DAYS + 1,
				'days'
			)
		);
		updateUser.unblockedUserId = null;

		return this.update(updateUser).then(() => {
			item.blockedUntil = updateUser.blockedUntil;
			item.unblockedUserId = updateUser.unblockedUserId;
			return Promise.resolve(item);
		});
	}

	public unblock(item: User, unblockedUserId: string): Promise<User> {
		if (item.blockedUntil) {
			// Do not use the loggedin user directly, if an error occurres
			const updateUser = { ...item };
			updateUser.blockedUntil = null;
			updateUser.unblockedUserId = unblockedUserId;
			return this.update(updateUser).then(() => {
				item.blockedUntil = updateUser.blockedUntil;
				item.unblockedUserId = updateUser.unblockedUserId;
				return Promise.resolve(item);
			});
		}
		return Promise.resolve(item);
	}

	public isBlocked(item: User): Promise<boolean> {
		if (!item.blockedUntil) {
			return Promise.resolve(false);
		}

		return this.timeService
			.serverTimestampAsPromise()
			.then(serverTime => serverTime.seconds < item.blockedUntil.seconds);
	}

	public delete(id: string): Promise<boolean> {
		return this.repository.deleteUser(id).then(res => !!res.isDeleted);
	}

	public getDefaultSettings(id: string): Observable<UserSettings> {
		return this.repository.getDefaultSettings(id);
	}

	public setDefaultSettings(
		id: string,
		companyId: string,
		locationId: string,
		eventId: string
	): Promise<string> {
		const userSettings = UserSettings.build(companyId, locationId, eventId);

		return this.repository.setDefaultSettings(id, userSettings);
	}

	public getUserInfo(userId: string): Promise<UserInfo | null> {
		return this.repository.getUserInfo(userId);
	}

	public enableFaceRecognitionFeature(user: User | UserInfo): Promise<void> {
		if (!user.isFaceRecognitionFeatureEnabled) {
			return this.repository.enableFaceRecognitionFeature(user.id);
		}
		return Promise.resolve();
	}
}
