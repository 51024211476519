import { Injectable } from '@angular/core';
import { FileSharer } from '@byteowls/capacitor-filesharer';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { detectBrowserName } from '@nova-shared/device-permission/utils/detect-browser-name';
import { NotificationService } from '@nova-shared/notification.service';
import { saveAs } from 'file-saver';

@Injectable({
	providedIn: 'root'
})
export class DownloadService {
	constructor(
		private platform: Platform,
		private notificationService: NotificationService
	) {}

	public download(
		data: Blob | string,
		filename: string,
		asText = false
	): Promise<void> {
		return new Promise((resolve, reject) => {
			// Use share on native device. Also on iOS in Safari, since normal file download does not work
			if (
				Capacitor.isNativePlatform() ||
				(this.platform.is('ios') && detectBrowserName() === 'safari')
			) {
				if (typeof data === 'string') {
					if (data.indexOf('https://') === 0) {
						this.loadFileFromURL(data, asText)
							.then(dataUrl =>
								this.notificationService
									.hideSpinner()
									.then(() => Promise.resolve(dataUrl))
							)
							.then(dataUrl =>
								this.shareDataUrl(dataUrl, filename).then(() =>
									resolve()
								)
							)
							.catch(err => reject(err));
						return;
					}
					this.notificationService.hideSpinner().then(() =>
						this.shareDataUrl(data, filename)
							.then(() => resolve())
							.catch(err => reject(err))
					);
				} else {
					this.loadFileFromBlob(data, asText)
						.then(dataUrl =>
							this.notificationService
								.hideSpinner()
								.then(() => Promise.resolve(dataUrl))
						)
						.then(dataUrl =>
							this.shareDataUrl(dataUrl, filename).then(() =>
								resolve()
							)
						)
						.catch(err => reject(err));
				}
			} else {
				this.notificationService
					.hideSpinner()
					.then(() =>
						saveAs(data, DownloadService.encodeFilename(filename))
					);
				return resolve();
			}
		});
	}

	public loadFileFromBlob(file: Blob, asText = false): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				resolve(reader.result as string);
			};
			reader.onerror = error => {
				reject(error);
			};
			if (asText) {
				reader.readAsText(file);
			} else {
				reader.readAsDataURL(file);
			}
		});
	}

	public loadFileFromURL(url: string, asText = false): Promise<string> {
		return fetch(url).then(response =>
			response.blob().then(blob => this.loadFileFromBlob(blob, asText))
		);
	}

	public static encodeFilename(filename: string): string {
		return filename?.replace(/([^a-zA-Z0-9_.-]+)/gi, '_') ?? '';
	}

	private shareDataUrl(dataUrl: string, filename: string): Promise<void> {
		const mimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0];
		const base64Data = dataUrl.split(',')[1];

		return FileSharer.share({
			filename: DownloadService.encodeFilename(filename),
			base64Data,
			contentType: mimeType
		}).catch(error => {
			return error === 'USER_CANCELLED' ||
				error.message === 'USER_CANCELLED'
				? Promise.resolve()
				: Promise.reject(error);
		});
	}
}
