import { Inject, Injectable } from '@angular/core';
import { Photo } from '@nova-shared/camera/photo';

import { FaceRecognitionPhoto } from './face-recognition-photo';
import {
	FACE_RECOGNITION_REPOSITORY,
	FaceRecognitionRepository
} from './face-recognition-repository';
import { FaceRecognitionStoreResult } from './face-recognition-store-result';

@Injectable({
	providedIn: 'root'
})
export class FaceRecognitionService {
	public static FACE_RECOGNITION_PHOTO_PREFIX = 'abis://';

	constructor(
		@Inject(FACE_RECOGNITION_REPOSITORY)
		private readonly repository: FaceRecognitionRepository
	) {}

	public validatePhoto(photo: Photo): Promise<boolean> {
		const faceRecognitionPhoto = this.buildFaceRecognitionPhoto(photo);
		if (!faceRecognitionPhoto) {
			return Promise.reject();
		}

		return this.repository
			.validate(faceRecognitionPhoto)
			.then(isValid => Promise.resolve(isValid));
	}

	public storePhoto(
		userId: string,
		photo: Photo,
		verifiedFalseHits?: string[],
		accountToDelete?: string
	): Promise<FaceRecognitionStoreResult> {
		const faceRecognitionPhoto = this.buildFaceRecognitionPhoto(photo);
		if (!userId || !faceRecognitionPhoto) {
			return Promise.reject();
		}

		return this.repository
			.store(
				userId,
				faceRecognitionPhoto,
				verifiedFalseHits,
				accountToDelete
			)
			.then(result => {
				if (result.success) {
					result.reference =
						FaceRecognitionService.FACE_RECOGNITION_PHOTO_PREFIX +
						userId;
				}
				return Promise.resolve(result);
			});
	}

	public getPhoto(userId: string): Promise<Photo> {
		if (!userId) {
			return Promise.reject();
		}

		return this.repository
			.read(userId)
			.then(photo =>
				Promise.resolve(
					photo?.base64Data
						? Photo.fromBase64(photo.base64Data, photo.format)
						: null
				)
			);
	}

	public deletePhoto(userId: string): Promise<boolean> {
		if (!userId) {
			return Promise.reject();
		}

		return this.repository
			.delete(userId)
			.then(success => Promise.resolve(!!success));
	}

	private buildFaceRecognitionPhoto(
		photo: Photo
	): FaceRecognitionPhoto | null {
		const photoData = photo?.getBase64();
		return photoData
			? {
					base64Data: photoData,
					format: Photo.getPhotoType(photo.format) ?? 'jpg'
			  }
			: null;
	}
}
