import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CameraModule } from '@nova-shared/camera/camera.module';
import { DirectivesModule } from '@nova-shared/directives/directives.module';
import { FormatersModule } from '@nova-shared/formaters/formaters.module';
import { LayoutComponentsModule } from '@nova-shared/layout-components/layout-components.module';

import { QrScannerButtonComponent } from './qr-scanner-button/qr-scanner-button.component';
import { QrScannerModal } from './qr-scanner-modal/qr-scanner.modal';
import { QrScannerPrivacyModal } from './qr-scanner-privacy-modal/qr-scanner-privacy.modal';
import { QrScannerComponent } from './qr-scanner.component';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		DirectivesModule,
		FormsModule,
		ReactiveFormsModule,
		FormatersModule,
		LayoutComponentsModule,
		CameraModule,
		TranslateModule
	],
	declarations: [
		QrScannerButtonComponent,
		QrScannerComponent,
		QrScannerModal,
		QrScannerPrivacyModal
	],
	exports: [QrScannerButtonComponent, QrScannerComponent],
	providers: []
})
export class QrScannerModule {}
