import { Photo as CameraPhoto } from '@capacitor/camera';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const instanceOfPhoto = (object: any): object is Photo => {
	return (
		object &&
		typeof object === 'object' &&
		('reference' in object || 'dataUrl' in object)
	);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const photoHasData = (object: any): boolean => {
	return instanceOfPhoto(object) && object.hasData();
};

export class Photo {
	private static readonly BASE64_MARKER = ';base64,';

	constructor(
		public reference: string,
		public dataUrl: string,
		public format: string,
		public width?: number,
		public height?: number
	) {}

	public static build(photo: Photo | string | null): Photo | null {
		return photo
			? instanceOfPhoto(photo)
				? photo
				: Photo.fromReference(photo)
			: null;
	}

	public static fromCameraPhoto(
		cameraPhoto: CameraPhoto,
		width?: number,
		height?: number
	): Photo {
		return new Photo(
			null,
			cameraPhoto.dataUrl,
			cameraPhoto.format,
			width,
			height
		);
	}

	public static fromReference(reference: string): Photo {
		return new Photo(reference, null, null);
	}

	public static fromBase64(base64Data: string, format: string): Photo {
		const dataUrl = `data:image/${
			Photo.getPhotoType(format)?.replace('jpg', 'jpeg') ?? 'jpeg'
		}${Photo.BASE64_MARKER}${base64Data}`;
		return Photo.fromBase64Url(dataUrl, format);
	}

	public static fromBase64Url(dataUrl: string, format: string): Photo {
		return new Photo(null, dataUrl, format);
	}

	public static getPhotoType(
		format: string
	): 'jpg' | 'jpeg' | 'png' | 'gif' | null {
		switch (
			format?.includes('/')
				? format.toLowerCase().split('/')[1]
				: format?.toLowerCase()
		) {
			case 'jpg':
				return 'jpg';
			case 'jpeg':
				return 'jpeg';
			case 'png':
				return 'png';
			case 'gif':
				return 'gif';

			default:
				return null;
		}
	}

	public getExtension(): string {
		if (this.dataUrl) {
			return `.${Photo.getPhotoType(this.format) ?? 'jpg'}`;
		} else if (this.reference?.indexOf('.') >= 0) {
			return `.${this.reference.split('.').pop()}`;
		}
		return '';
	}

	public getBase64(): string {
		if (this.dataUrl) {
			const index = this.dataUrl.indexOf(Photo.BASE64_MARKER);
			return index >= 0
				? this.dataUrl.substring(index + Photo.BASE64_MARKER.length)
				: this.dataUrl;
		}
		return null;
	}

	public getImage(): Promise<HTMLImageElement> {
		if (!this.dataUrl) {
			return Promise.resolve(null);
		}

		return new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = () => {
				resolve(image);
			};
			image.onerror = error => reject(error);
			image.src = this.dataUrl;
		});
	}

	public hasData(): boolean {
		return !!this.reference || !!this.dataUrl;
	}
}
