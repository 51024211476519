import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { environment } from '@nova-environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private loadingSpinners: HTMLIonLoadingElement[] = [];

	constructor(
		private loadingController: LoadingController,
		private toastController: ToastController,
		private alertController: AlertController,
		private i18nService: I18nService
	) {}

	public showSpinner(tag?: string, params?: object): Promise<void> {
		return this.hideSpinner().then(() =>
			this.loadingController
				.create({
					translucent: true,
					message: this.i18nService.getInstant(tag, params)
				})
				.then(loading => {
					this.loadingSpinners.push(loading);
					return loading.present();
				})
		);
	}

	public hideSpinner(): Promise<void> {
		return this.loadingSpinners.length
			? Promise.all(
					this.loadingSpinners.map(loading => loading.dismiss())
			  ).then(() => Promise.resolve())
			: Promise.resolve();
	}

	public isSpinnerVisible(): boolean {
		return this.loadingSpinners.length > 0;
	}

	public showError(
		error: Error | string,
		duration: number = 8000,
		params?: object
	): Promise<void> {
		if (environment.clientLogEnabled && !(typeof error === 'string')) {
			console.error(error);
		}
		return this.showMessage(
			typeof error === 'string'
				? error
				: 'generic.messages.errorTryAgain',
			duration,
			params
		);
	}

	public showMessage(
		tag: string,
		duration: number = 4000,
		params?: object,
		cssClass?: string,
		icon?: string,
		centered = false
	): Promise<void> {
		return this.hideSpinner().then(() =>
			this.toastController
				.create({
					message: this.i18nService.getInstant(tag, params),
					icon,
					duration,
					position: centered ? 'middle' : 'bottom',
					cssClass: `${
						centered && icon ? 'big-icon' : undefined
					} ${cssClass}`,
					translucent: true
				})
				.then(toast => toast.present())
		);
	}

	public showIconMessage(
		tag: string,
		cssClass: string = 'success',
		icon: string = 'checkmark-circle-outline',
		duration: number = 6000,
		params?: object
	): Promise<void> {
		return this.showMessage(tag, duration, params, cssClass, icon, true);
	}

	public showFatalError(tag?: string, params?: object): Promise<void> {
		let message = this.i18nService.getInstant(
			tag || 'generic.messages.fatalError',
			params
		);
		message =
			message === (tag || 'generic.messages.fatalError')
				? 'Error'
				: message;

		let buttonLabel = this.i18nService.getInstant('generic.reload');
		buttonLabel = buttonLabel === 'generic.reload' ? 'Reload' : buttonLabel;

		return this.hideSpinner()
			.then(() =>
				this.loadingController
					.create({
						showBackdrop: true,
						backdropDismiss: false,
						keyboardClose: false,
						spinner: null,
						message: null,
						cssClass: 'backdrop'
					})
					.then(backdrop => backdrop.present())
			)
			.then(() =>
				this.toastController
					.create({
						message,
						position: 'middle',
						translucent: true,
						buttons: [
							{
								side: 'end',
								icon: 'reload-outline',
								text: buttonLabel,
								role: 'reload',
								handler: () => {
									window.location.reload();
								}
							}
						]
					})
					.then(toast => toast.present())
			);
	}

	public showAlert(
		titleTag: string,
		messageTag: string,
		params?: object
	): Promise<void> {
		return this.hideSpinner().then(() =>
			this.alertController
				.create({
					translucent: true,
					header: this.i18nService.getInstant(titleTag),
					message: this.i18nService.getInstant(messageTag, params),
					buttons: [this.i18nService.getInstant('generic.ok')]
				})
				.then(alert => alert.present())
		);
	}

	public showConfirm(
		titleTag: string,
		messageTag: string,
		continueOnly = false,
		params?: object,
		yesLabelKey?: string,
		noLabelKey?: string,
		continueLabelKey?: string
	): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.hideSpinner().then(() =>
				this.alertController
					.create({
						translucent: true,
						backdropDismiss: false,
						header: this.i18nService.getInstant(titleTag),
						message: this.i18nService.getInstant(
							messageTag,
							params
						),
						buttons: continueOnly
							? [
									{
										text: this.i18nService.getInstant(
											continueLabelKey ??
												'generic.continue'
										),
										handler: () => {
											resolve(true);
										}
									}
							  ]
							: [
									{
										text: this.i18nService.getInstant(
											noLabelKey ?? 'generic.no'
										),
										handler: () => {
											resolve(false);
										}
									},
									{
										text: this.i18nService.getInstant(
											yesLabelKey ?? 'generic.yes'
										),
										handler: () => {
											resolve(true);
										}
									}
							  ]
					})
					.then(alert => alert.present())
			);
		});
	}
}
