import { Component, ComponentRef, EventEmitter, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

import { AppBannerSettings } from './app-banner.service';

@Component({
	selector: 'app-app-banner',
	templateUrl: './app-banner.component.html',
	styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent {
	@Input()
	public settings: AppBannerSettings;
	@Input()
	public componentRef: ComponentRef<AppBannerComponent>;

	public onClose: EventEmitter<void>;
	public modifier: string;
	public show = true;

	constructor(private readonly platform: Platform) {
		this.onClose = new EventEmitter();

		if (this.platform.is('android')) {
			this.modifier = 'android';
		}
		if (this.platform.is('ios')) {
			this.modifier = 'ios';
		}
	}

	public get iconUrl(): string {
		if (this.platform.is('android')) {
			return this.settings?.icon?.android;
		}
		if (this.platform.is('ios')) {
			return this.settings?.icon?.ios;
		}
		return 'assets/icons/icon-128x128.png';
	}

	public get buttonUrl(): string {
		if (this.platform.is('android')) {
			return this.settings?.buttonUrl?.android;
		}
		if (this.platform.is('ios')) {
			return this.settings?.buttonUrl?.ios;
		}
		return 'https://app.nova-app.de';
	}

	public close(): void {
		this.onClose.emit();
		this.show = false;
	}

	public openStore(): void {
		window.open(this.buttonUrl, '_system');
	}
}
