import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
	providedIn: 'root'
})
export class UserDefaultsService {
	private static SETTINGS_CAMERA_FLIPPED = 'settingsCameraFlipped';
	private static CURRENT_USER_PROVIDER_ID = 'currentUserProviderId';
	private static QR_SCANNER_PRIVACY_CONFIRMED = 'qrScannerPrivacyConfirmed';
	private static USER_ID_ASKED_FOR_FCM = 'userIdAskedForFcm';
	private static SMARTKEY_BL_PRIVACY_CONFIRMED = 'smartkeyBlPrivacyConfirmed';
	private static SMARTKEY_PROXY_INFO_SETUP_REQUIRED_CONFIRMED =
		'smartkeyProxyInfoSetupRequiredConfirmed';
	private static SMARTKEY_PROXY_INFO_UNHEALTHY_TIMESTAMP =
		'smartkeyProxyInfoUnhealthyTimestamp';
	private static SMARTKEY_PROXY_INFO_UPDATE_AVAILABLE_CONFIRMED_VERSION =
		'smartkeyProxyInfoUpdateAvailableConfirmedVersion';
	private static DEVICE_TOKEN = 'deviceToken';
	private static LAST_HANDLED_ERROR_TIMESTAMP = 'lastHandledErrorTimestamp';
	private static HANDLED_ERROR_RETRIES_COUNTER = 'handledErrorRetriesCounter';

	constructor() {}

	public async resetOnLogin(userId: string | null): Promise<void> {
		const lastUserId = await this.getItem('lastUserId');
		if (!userId || lastUserId === userId) {
			return Promise.resolve();
		}
		await this.setItem('lastUserId', userId);

		await this.setDeviceToken(null);
		await this.setQrScannerPrivacyConfirmed(null);
		await this.setSmartkeyBlPrivacyConfirmed(null);
		await this.setSmartkeyProxyInfoSetupRequiredConfirmed(null);
		await this.setSmartkeyProxyInfoUnhealthyTimestamp(null);
		await this.setSmartkeyProxyInfoUpdateAvailableConfirmedVersion(null);

		return Promise.resolve();
	}

	public setSettingsCameraFlipped(flipped: boolean | null): Promise<void> {
		if (flipped !== null) {
			return this.setItem(
				UserDefaultsService.SETTINGS_CAMERA_FLIPPED,
				flipped ? 'true' : 'false'
			);
		} else {
			return this.removeItem(UserDefaultsService.SETTINGS_CAMERA_FLIPPED);
		}
	}

	public getSettingsCameraFlipped(): Promise<boolean | null> {
		return this.getItem(UserDefaultsService.SETTINGS_CAMERA_FLIPPED).then(
			v => (v ? v === 'true' : null)
		);
	}

	public setCurrentUserProviderId(providerId: string | null): Promise<void> {
		if (providerId) {
			return this.setItem(
				UserDefaultsService.CURRENT_USER_PROVIDER_ID,
				providerId
			);
		} else {
			return this.removeItem(
				UserDefaultsService.CURRENT_USER_PROVIDER_ID
			);
		}
	}

	public getCurrentUserProviderId(): Promise<string | null> {
		return this.getItem(UserDefaultsService.CURRENT_USER_PROVIDER_ID);
	}

	public setQrScannerPrivacyConfirmed(
		confirmed: boolean | null
	): Promise<void> {
		if (confirmed !== null) {
			return this.setItem(
				UserDefaultsService.QR_SCANNER_PRIVACY_CONFIRMED,
				confirmed ? 'true' : 'false'
			);
		} else {
			return this.removeItem(
				UserDefaultsService.QR_SCANNER_PRIVACY_CONFIRMED
			);
		}
	}

	public getQrScannerPrivacyConfirmed(): Promise<boolean | null> {
		return this.getItem(
			UserDefaultsService.QR_SCANNER_PRIVACY_CONFIRMED
		).then(v => (v ? v === 'true' : null));
	}

	public setUserIdAskedForFcm(userId: string | null): Promise<void> {
		if (userId !== null) {
			return this.setItem(
				UserDefaultsService.USER_ID_ASKED_FOR_FCM,
				userId
			);
		} else {
			return this.removeItem(UserDefaultsService.USER_ID_ASKED_FOR_FCM);
		}
	}

	public getUserIdAskedForFcm(): Promise<string | null> {
		return this.getItem(UserDefaultsService.USER_ID_ASKED_FOR_FCM);
	}

	public setSmartkeyBlPrivacyConfirmed(
		confirmed: boolean | null
	): Promise<void> {
		if (confirmed !== null) {
			return this.setItem(
				UserDefaultsService.SMARTKEY_BL_PRIVACY_CONFIRMED,
				confirmed ? 'true' : 'false'
			);
		} else {
			return this.removeItem(
				UserDefaultsService.SMARTKEY_BL_PRIVACY_CONFIRMED
			);
		}
	}

	public getSmartkeyBlPrivacyConfirmed(): Promise<boolean | null> {
		return this.getItem(
			UserDefaultsService.SMARTKEY_BL_PRIVACY_CONFIRMED
		).then(v => (v ? v === 'true' : null));
	}

	public setSmartkeyProxyInfoSetupRequiredConfirmed(
		confirmed: boolean | null
	): Promise<void> {
		if (confirmed !== null) {
			return this.setItem(
				UserDefaultsService.SMARTKEY_PROXY_INFO_SETUP_REQUIRED_CONFIRMED,
				confirmed ? 'true' : 'false'
			);
		} else {
			return this.removeItem(
				UserDefaultsService.SMARTKEY_PROXY_INFO_SETUP_REQUIRED_CONFIRMED
			);
		}
	}

	public getSmartkeyProxyInfoSetupRequiredConfirmed(): Promise<
		boolean | null
	> {
		return this.getItem(
			UserDefaultsService.SMARTKEY_PROXY_INFO_SETUP_REQUIRED_CONFIRMED
		).then(v => (v ? v === 'true' : null));
	}

	public getSmartkeyProxyInfoUnhealthyTimestamp(): Promise<number | null> {
		return this.getItem(
			UserDefaultsService.SMARTKEY_PROXY_INFO_UNHEALTHY_TIMESTAMP
		).then(v => (v === null ? null : +v));
	}

	public setSmartkeyProxyInfoUnhealthyTimestamp(
		timestamp: number | null
	): Promise<void> {
		if (timestamp) {
			return this.setItem(
				UserDefaultsService.SMARTKEY_PROXY_INFO_UNHEALTHY_TIMESTAMP,
				`${timestamp}`
			);
		} else {
			return this.removeItem(
				UserDefaultsService.SMARTKEY_PROXY_INFO_UNHEALTHY_TIMESTAMP
			);
		}
	}

	public setSmartkeyProxyInfoUpdateAvailableConfirmedVersion(
		version: string | null
	): Promise<void> {
		if (version) {
			return this.setItem(
				UserDefaultsService.SMARTKEY_PROXY_INFO_UPDATE_AVAILABLE_CONFIRMED_VERSION,
				version
			);
		} else {
			return this.removeItem(
				UserDefaultsService.SMARTKEY_PROXY_INFO_UPDATE_AVAILABLE_CONFIRMED_VERSION
			);
		}
	}

	public getSmartkeyProxyInfoUpdateAvailableConfirmedVersion(): Promise<
		string | null
	> {
		return this.getItem(
			UserDefaultsService.SMARTKEY_PROXY_INFO_UPDATE_AVAILABLE_CONFIRMED_VERSION
		);
	}

	public setDeviceToken(deviceToken: string | null): Promise<void> {
		if (deviceToken) {
			return this.setItem(UserDefaultsService.DEVICE_TOKEN, deviceToken);
		} else {
			return this.removeItem(UserDefaultsService.DEVICE_TOKEN);
		}
	}

	public getDeviceToken(): Promise<string | null> {
		return this.getItem(UserDefaultsService.DEVICE_TOKEN);
	}

	public getLastHandledErrorTimestamp(): Promise<number | null> {
		return this.getItem(
			UserDefaultsService.LAST_HANDLED_ERROR_TIMESTAMP
		).then(v => (v === null ? null : +v));
	}

	public setLastHandledErrorTimestamp(
		timestamp: number | null
	): Promise<void> {
		if (timestamp) {
			return this.setItem(
				UserDefaultsService.LAST_HANDLED_ERROR_TIMESTAMP,
				`${timestamp}`
			);
		} else {
			return this.removeItem(
				UserDefaultsService.LAST_HANDLED_ERROR_TIMESTAMP
			);
		}
	}

	public getHandledErrorRetriesCounter(): Promise<number> {
		return this.getItem(
			UserDefaultsService.HANDLED_ERROR_RETRIES_COUNTER
		).then(v => (v === null ? 0 : +v));
	}

	public setHandledErrorRetriesCounter(
		counter: number | null
	): Promise<void> {
		if (counter) {
			return this.setItem(
				UserDefaultsService.HANDLED_ERROR_RETRIES_COUNTER,
				`${counter}`
			);
		} else {
			return this.removeItem(
				UserDefaultsService.HANDLED_ERROR_RETRIES_COUNTER
			);
		}
	}

	private setObject(key: string, value: any): Promise<void> {
		return Preferences.set({
			key,
			value: JSON.stringify(value)
		});
	}

	private getObject(key: string): Promise<any> {
		return Preferences.get({ key }).then(ret =>
			ret && ret.value ? JSON.parse(ret.value) : null
		);
	}

	private setItem(key: string, value: string): Promise<void> {
		return Preferences.set({
			key,
			value
		});
	}

	private getItem(key: string): Promise<string> {
		return Preferences.get({ key }).then(ret => (ret ? ret.value : null));
	}

	private removeItem(key: string): Promise<void> {
		return Preferences.remove({ key });
	}
}
