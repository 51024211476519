import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NumbersOnlyDirective } from './numbers-only.directive';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';

@NgModule({
	declarations: [PreventDoubleClickDirective, NumbersOnlyDirective],
	imports: [CommonModule],
	exports: [PreventDoubleClickDirective, NumbersOnlyDirective]
})
export class DirectivesModule {}
