import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Messaging } from './messaging';
import { MESSAGING_REPOSITORY, MessagingRepository } from './messaging.repository';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	constructor(
		@Inject(MESSAGING_REPOSITORY)
		private readonly repository: MessagingRepository
	) {}

	public listUnread(userId: string): Observable<Messaging[]> {
		return this.repository.listUnread(userId);
	}

	public updateIsRead(userId: string, messaging: Messaging): Promise<void> {
		return this.repository.updateIsRead(userId, messaging);
	}

	public remove(userId: string, messagingId: string): Promise<boolean> {
		return this.repository.remove(userId, messagingId);
	}

	public create(receiverId: string, messaging: Messaging): Promise<string> {
		return this.repository.add(receiverId, messaging);
	}
}
