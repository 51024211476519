import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { TimestampUtils } from '@nova-core/time/timestamp.utils';

import { CwaConfirmationType } from './cwa-confirmation';
import { UserContactData } from './user-contact-data';

export interface UserDeviceToken {
	token: string;
	timestamp: Timestamp | FieldValue;
}
export class User implements UserContactData {
	public static readonly BLOCK_EXPIRATION_DAYS = 14;

	constructor(
		public id: string,
		public firstName: string,
		public lastName: string,
		public birthdate: Timestamp,
		public street: string,
		public houseNumber: string,
		public postalCode: string,
		public city: string,
		public country: string,
		public email: string,
		public phone: string,
		public language: string,
		public timeZone: string,
		public privacyPolicyConfirmed: boolean,
		public blockedUntil: Timestamp,
		public unblockedUserId: string,
		public temporary: boolean,
		public faceRecognitionPhoto: string,
		public phoneVerified: boolean = false,
		public emailVerified: boolean = false,
		public timestamp: Timestamp | FieldValue,
		public tokenClaimsUpdatedAt: Timestamp,
		public isFaceRecognitionFeatureEnabled = false,
		public isOperatorFeatureEnabled = false,
		public isUnblockFeatureEnabled = false,
		public cwaConfirmationType: CwaConfirmationType = null,
		public idCardNo: string | null = null,
		public cwaDccGenerationApproved: boolean = false,
		public deviceTokens: UserDeviceToken[] | null = null
	) {}

	public static build(
		id: string,
		temporary: boolean,
		language: string,
		email?: string,
		emailVerified?: boolean,
		firstName?: string,
		lastName?: string
	): User {
		return new User(
			id,
			firstName,
			lastName,
			null,
			null,
			null,
			null,
			null,
			null,
			email,
			null,
			language ?? 'de',
			TimestampUtils.getTimeZone(),
			false,
			null,
			null,
			temporary,
			null,
			false,
			!!emailVerified,
			null,
			null
		);
	}

	public static isRegistered(user: User): boolean {
		return (
			!!user &&
			!!user.id &&
			!!user.firstName &&
			!!user.lastName &&
			!!user.birthdate &&
			!!user.street &&
			!!user.houseNumber &&
			!!user.postalCode &&
			!!user.city &&
			!!user.email &&
			!!user.phone &&
			!!user.phoneVerified &&
			!!user.country
		);
	}

	public static isUserDataAvailable(user: User): boolean {
		return (
			!!user &&
			!!user.id &&
			!!user.firstName &&
			!!user.lastName &&
			!!user.street &&
			!!user.houseNumber &&
			!!user.postalCode &&
			!!user.city &&
			!!user.email &&
			!!user.country
		);
	}

	public static getLegalDate(birthdate: Timestamp): Timestamp | null {
		return birthdate
			? TimestampUtils.toTimestamp(
					TimestampUtils.toMoment(birthdate).add(18, 'years')
			  )
			: null;
	}
}
