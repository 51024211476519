import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiFireStorageClient } from '@nova-core/api-client/api-firestorage-client.service';
import { FaceRecognitionService } from '@nova-features/face-recognition/face-recognition.service';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { LightboxComponent } from '../lightbox/lightbox.component';
import { instanceOfPhoto, Photo } from '../photo';

@Component({
	selector: 'app-photo',
	templateUrl: './photo.component.html',
	styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnChanges, OnDestroy {
	@Input()
	public photo: Photo | string | Promise<Photo> | Observable<Photo>;
	@Input()
	public imgSrc: string | SafeResourceUrl; // Provide image src directly (e.g. used by lightbox)
	@Input()
	public type: 'default' | 'avatar' = 'default';
	@Input()
	public enableLightbox = false;

	@Output()
	public loaded = new EventEmitter<void>();

	@ViewChild('lightbox')
	public lightbox: LightboxComponent;

	@HostBinding('class.loading')
	public isLoading = true;
	@HostBinding('class.type-default')
	public isTypeDefault = true;
	@HostBinding('class.type-avatar')
	public isTypeAvatar = false;

	public isLazyLoadingImg = true;

	private destruction = new Subject<void>();

	constructor(
		private storage: ApiFireStorageClient,
		private faceRecognitionService: FaceRecognitionService,
		private sanitizer: DomSanitizer
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		this.isTypeDefault = this.type === 'default';
		this.isTypeAvatar = this.type === 'avatar';

		this.isLazyLoadingImg = true;
		this.isLoading = true;
		if (Object.prototype.hasOwnProperty.call(changes, 'photo')) {
			if (this.photo instanceof Promise) {
				this.photo.then((photo: Photo) =>
					this.setImageSrc(photo?.dataUrl)
				);
			} else if (this.photo instanceof Observable) {
				this.photo
					.pipe(first(), takeUntil(this.destruction))
					.subscribe((photo: Photo) =>
						this.setImageSrc(photo?.dataUrl)
					);
			} else if (instanceOfPhoto(this.photo) && this.photo?.dataUrl) {
				this.isLazyLoadingImg = false;
				this.setImageSrc(this.photo.dataUrl);
			} else if (
				(instanceOfPhoto(this.photo) && this.photo?.reference) ||
				(this.photo && typeof this.photo === 'string')
			) {
				const reference = instanceOfPhoto(this.photo)
					? this.photo.reference
					: this.photo;

				if (
					reference.startsWith(
						FaceRecognitionService.FACE_RECOGNITION_PHOTO_PREFIX
					)
				) {
					this.faceRecognitionService
						.getPhoto(
							reference.substring(
								FaceRecognitionService
									.FACE_RECOGNITION_PHOTO_PREFIX.length
							)
						)
						.then(photo => this.setImageSrc(photo?.dataUrl));
				} else if (reference.startsWith('data:')) {
					this.isLazyLoadingImg = false;
					this.setImageSrc(
						this.sanitizer.bypassSecurityTrustResourceUrl(reference)
					);
				} else {
					this.storage
						.getUrl(reference)
						.then(url => this.setImageSrc(url));
				}
			} else {
				this.isLazyLoadingImg = false;
				this.setImageSrc('');
			}
		} else if (Object.prototype.hasOwnProperty.call(changes, 'imgSrc')) {
			this.isLazyLoadingImg = false;
			this.setImageSrc(this.imgSrc);
		} else {
			this.isLazyLoadingImg = false;
			this.setImageSrc('');
		}
	}

	public ngOnDestroy(): void {
		this.destruction.next();
		this.destruction.complete();

		this.hideLightbox();
	}

	public showLightbox(): void {
		if (this.enableLightbox) {
			this.lightbox.show();
		}
	}

	public hideLightbox(): void {
		if (this.enableLightbox) {
			this.lightbox.hide();
		}
	}

	public onImageDidLoad(): void {
		this.isLoading = false;
		this.loaded.emit();
	}

	private setImageSrc(imageSrc: string | SafeResourceUrl): void {
		if (this.imgSrc?.toString() !== imageSrc?.toString()) {
			this.isLoading = !!imageSrc && this.isLazyLoadingImg;
			this.imgSrc = imageSrc;
		} else {
			this.isLoading = false;
		}
	}
}
