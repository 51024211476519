import { serverTimestamp } from '@angular/fire/firestore';
import { ApiFirebaseClient } from '@nova-core/api-client/api-firebase-client.service';
import { ApiFirestoreClient } from '@nova-core/api-client/api-firestore-client.service';
import { Observable, of } from 'rxjs';

import { UnknownUserError } from './unknown-user.error';
import { User, UserDeviceToken } from './user';
import { UserInfo } from './user-info';
import { UserRepository } from './user-repository';
import { UserSettings } from './user-settings';

export class UserFirebaseRepository implements UserRepository {
	public static readonly COLLECTION = 'users';
	public static readonly COLLECTION_USER_SETTINGS = 'userSettings';
	public static readonly GET_COLLECTION_USER_SETTINGS = (
		userId: string
	): string =>
		`${UserFirebaseRepository.COLLECTION}/${userId}/${UserFirebaseRepository.COLLECTION_USER_SETTINGS}`;
	public static readonly USER_SETTINGS_ID_DEFAULT: string = 'default';

	private static readonly FUNCTION_USER_INFO = 'getUserInfo';
	private static readonly FUNCTION_DELETE_USER = 'deleteUser';

	constructor(
		private db: ApiFirestoreClient,
		private fns: ApiFirebaseClient
	) {}

	public single(id: string): Observable<User> {
		return id
			? this.db.item<User>(UserFirebaseRepository.COLLECTION, id)
			: of(null);
	}

	public add(item: User): Promise<string> {
		if (!item.id) {
			item = this.db.addId(item);
		}
		item.timestamp = serverTimestamp();
		return this.db.add<User>(UserFirebaseRepository.COLLECTION, item);
	}

	public update(item: User): Promise<void> {
		return this.db.update<User>(
			UserFirebaseRepository.COLLECTION,
			item.id,
			item
		);
	}

	public updateFaceRecognition(
		id: string,
		faceRecognitionPhoto?: string
	): Promise<void> {
		return this.db.update<Partial<User>>(
			UserFirebaseRepository.COLLECTION,
			id,
			{
				faceRecognitionPhoto: faceRecognitionPhoto ?? null
			}
		);
	}

	public updateDeviceTokens(
		id: string,
		deviceTokens: UserDeviceToken[],
		language?: string,
		timeZone?: string
	): Promise<void> {
		return this.db.update<Partial<User>>(
			UserFirebaseRepository.COLLECTION,
			id,
			{
				...{
					deviceTokens
				},
				...(language ? { language } : {}),
				...(timeZone ? { timeZone } : {})
			}
		);
	}

	public updateLocaleData(
		id: string,
		language: string,
		timeZone: string
	): Promise<void> {
		return this.db.update<Partial<User>>(
			UserFirebaseRepository.COLLECTION,
			id,
			{
				language,
				timeZone
			}
		);
	}

	public deleteUser(userId: string): Promise<{ isDeleted: boolean }> {
		return this.fns.call<{ userId: string }, { isDeleted: boolean }>(
			UserFirebaseRepository.FUNCTION_DELETE_USER,
			{ userId }
		);
	}

	public enableFaceRecognitionFeature(id: string): Promise<void> {
		return this.db.update<{ isFaceRecognitionFeatureEnabled?: boolean }>(
			UserFirebaseRepository.COLLECTION,
			id,
			{
				isFaceRecognitionFeatureEnabled: true
			}
		);
	}

	public getDefaultSettings(userId: string): Observable<UserSettings> {
		return userId
			? this.db.item<UserSettings>(
					UserFirebaseRepository.GET_COLLECTION_USER_SETTINGS(userId),
					UserFirebaseRepository.USER_SETTINGS_ID_DEFAULT
			  )
			: of(null);
	}

	public setDefaultSettings(
		userId: string,
		item: UserSettings
	): Promise<string> {
		return this.db.add<UserSettings>(
			UserFirebaseRepository.GET_COLLECTION_USER_SETTINGS(userId),
			item
		);
	}

	public getUserInfo(userId: string): Promise<UserInfo> {
		return userId
			? this.fns
					.call<string, UserInfo>(
						UserFirebaseRepository.FUNCTION_USER_INFO,
						userId
					)
					.catch(error =>
						error.code === ApiFirebaseClient.ErrorCode.NOT_FOUND
							? Promise.reject(
									new UnknownUserError('Unknown user')
							  )
							: Promise.reject(error)
					)
			: Promise.resolve(null);
	}
}
