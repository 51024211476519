import { NgModule } from '@angular/core';

import { FirebaseDatePipe } from './firebase-date.pipe';

@NgModule({
	declarations: [FirebaseDatePipe],
	providers: [FirebaseDatePipe],
	exports: [FirebaseDatePipe]
})
export class FormatersModule {}
