import { inject, InjectionToken } from '@angular/core';
import { ApiFirebaseClient } from '@nova-core/api-client/api-firebase-client.service';
import { ApiFirestoreClient } from '@nova-core/api-client/api-firestore-client.service';
import { environment } from '@nova-environments/environment';
import { Observable } from 'rxjs';

import { User, UserDeviceToken } from './user';
import { UserFirebaseRepository } from './user-firebase-repository';
import { UserInfo } from './user-info';
import { UserMockRepository } from './user-mock-repository';
import { UserSettings } from './user-settings';

export const USER_REPOSITORY = new InjectionToken<UserRepository>(
	'UserRepository',
	{
		providedIn: 'root',
		factory: () =>
			environment.useMockData
				? new UserMockRepository()
				: new UserFirebaseRepository(
						inject(ApiFirestoreClient),
						inject(ApiFirebaseClient)
				  )
	}
);

export interface UserRepository {
	single(id: string): Observable<User>;

	add(item: User): Promise<string>;

	update(item: User): Promise<void>;

	updateFaceRecognition(
		id: string,
		faceRecognitionPhoto?: string
	): Promise<void>;

	updateDeviceTokens(
		id: string,
		deviceTokens: UserDeviceToken[],
		language?: string,
		timeZone?: string
	): Promise<void>;

	updateLocaleData(
		id: string,
		language: string,
		timeZone: string
	): Promise<void>;

	deleteUser(id: string): Promise<{ isDeleted: boolean }>;

	enableFaceRecognitionFeature(id: string): Promise<void>;

	getDefaultSettings(userId: string): Observable<UserSettings>;

	setDefaultSettings(userId: string, item: UserSettings): Promise<string>;

	getUserInfo(userId: string): Promise<UserInfo>;
}
