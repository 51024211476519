import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private authService: AuthService) {}

	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkLogin(state.url);
	}

	public canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.canActivate(next, state);
	}

	public canLoad(
		route: Route,
		segments: UrlSegment[]
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkLogin(segments.join('/'));
	}

	private checkLogin(origin: string): boolean {
		// Logout anonymous users if still loggedin
		if (this.authService.isLoggedInAnonymous()) {
			this.authService.logout();
			return false;
		}

		// not logged in => login / registration
		if (!this.authService.isLoggedIn()) {
			this.authService.showLogin(origin);
			return false;
		}

		// not activated => redirect to not activated info
		if (!this.authService.isAccountActivated()) {
			this.authService.showAccountActivation(origin);
			return false;
		}

		// logged in, not registered => redirect to registration process (last step)
		if (!this.authService.isRegistered()) {
			this.authService.showRegistration(origin);
			return false;
		}

		// logged in, registered => access
		return true;
	}
}
