import { limit, orderBy, where } from '@angular/fire/firestore';
import { ApiFirestoreClient } from '@nova-core/api-client/api-firestore-client.service';
import { UserFirebaseRepository } from '@nova-features/user/user-firebase-repository';
import { Observable, of } from 'rxjs';

import { Messaging } from './messaging';
import { MessagingRepository } from './messaging.repository';

export class MessagingFirestoreRepository implements MessagingRepository {
	private static GET_COLLECTION = (userId: string): string =>
		`${UserFirebaseRepository.COLLECTION}/${userId}/${MessagingFirestoreRepository.COLLECTION}`;
	public static readonly COLLECTION = 'messaging';

	constructor(private db: ApiFirestoreClient) {}

	public listUnread(userId: string): Observable<Messaging[]> {
		return userId
			? this.db.collection<Messaging>(
					MessagingFirestoreRepository.GET_COLLECTION(userId),
					[
						where('isRead', '==', false),
						orderBy('createdAt', 'asc'),
						limit(1)
					]
			  )
			: of([]);
	}

	public update(userId: string, messaging: Messaging): Promise<void> {
		return this.db.update<Messaging>(
			MessagingFirestoreRepository.GET_COLLECTION(userId),
			messaging.id,
			messaging
		);
	}

	public updateIsRead(userId: string, messaging: Messaging): Promise<void> {
		return this.db.update<Messaging>(
			MessagingFirestoreRepository.GET_COLLECTION(userId),
			messaging.id,
			{ isRead: true }
		);
	}

	public add(receiverId: string, messaging: Messaging): Promise<string> {
		return this.db.add<Messaging>(
			MessagingFirestoreRepository.GET_COLLECTION(receiverId),
			messaging
		);
	}

	public remove(userId: string, messagingId: string): Promise<boolean> {
		return messagingId
			? this.db
					.delete(
						MessagingFirestoreRepository.GET_COLLECTION(userId),
						messagingId
					)
					.then(() => Promise.resolve(true))
			: Promise.resolve(false);
	}
}
