import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'visitor'
	},
	{
		path: 'registration',
		loadChildren: () =>
			import('@nova-pages/registration/registration.page.module').then(
				m => m.RegistrationPageModule
			)
	},
	{
		path: 'login',
		loadChildren: () =>
			import('@nova-pages/public/login/login.page.module').then(
				m => m.LoginPageModule
			)
	},
	{
		path: 'visitor',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('@nova-pages/visitor/visitor.page.module').then(
				m => m.VisitorPageModule
			)
	},
	{
		path: 'operator',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('@nova-pages/operator/operator.page.module').then(
				m => m.OperatorPageModule
			)
	},
	{
		path: 'admin',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('@nova-pages/admin/admin.page.module').then(
				m => m.AdminPageModule
			)
	},
	{
		path: 'check-in',
		loadChildren: () =>
			import('@nova-pages/checkin/checkin.page.module').then(
				m => m.CheckinPageModule
			)
	},
	{
		path: 'check-out',
		loadChildren: () =>
			import('@nova-pages/checkout/checkout.page.module').then(
				m => m.CheckoutPageModule
			)
	},
	{
		path: 'events',
		pathMatch: 'full',
		redirectTo: 'visitor'
	},
	{
		path: 'events',
		loadChildren: () =>
			import(
				'@nova-pages/event-registration/event-registration.page.module'
			).then(m => m.EventRegistrationPageModule)
	},
	{
		path: 'testresult',
		loadChildren: () =>
			import('@nova-pages/test-result/test-result.module').then(
				m => m.TestResultPageModule
			)
	},
	{
		path: 'hotel',
		loadChildren: () =>
			import('@nova-pages/hotel/hotel.page.module').then(
				m => m.HotelPageModule
			)
	},
	{
		path: 'privacy-policy',
		loadChildren: () =>
			import(
				'@nova-pages/public/privacy-policy/privacy-policy.page.module'
			).then(m => m.PrivacyPolicyPageModule)
	},
	{
		path: 'terms-conditions',
		loadChildren: () =>
			import(
				'@nova-pages/public/terms-conditions/terms-conditions.page.module'
			).then(m => m.TermsConditionsPageModule)
	},
	{
		path: 'imprint',
		loadChildren: () =>
			import('@nova-pages/public/imprint/imprint.page.module').then(
				m => m.ImprintPageModule
			)
	},
	{
		path: 'user',
		loadChildren: () =>
			import('@nova-pages/user/user.page.module').then(
				m => m.UserPageModule
			)
	},
	{
		path: 'face-recognition',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import(
				'@nova-pages/face-recognition/face-recognition.page.module'
			).then(m => m.FaceRecognitionPageModule)
	},
	{
		path: 'qr-printer',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('@nova-pages/qr-print/qr-print.page.module').then(
				m => m.QrPrintPageModule
			)
	},
	{
		path: 'error',
		loadChildren: () =>
			import('@nova-pages/public/error/error.page.module').then(
				m => m.ErrorPageModule
			)
	},
	{
		path: '**',
		redirectTo: '/error/notfound'
	}
];

const routingConfiguration: ExtraOptions = {
	paramsInheritanceStrategy: 'always',
	preloadingStrategy: PreloadAllModules,
	onSameUrlNavigation: 'reload'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routingConfiguration)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
