import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertButton, AlertController } from '@ionic/angular';
import { AuthService } from '@nova-core/auth/auth.service';
import { FcmService } from '@nova-core/fcm/fcm.service';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { environment } from '@nova-environments/environment';
import { Subscription } from 'rxjs';
import { distinct, filter } from 'rxjs/operators';

import { Messaging } from './messaging';
import { MessagingService } from './messaging.service';

@Injectable({
	providedIn: 'root'
})
export class MessagingController {
	public activeMessaging$: Subscription;
	public activeLoggedInUser$: Subscription;

	constructor(
		private authService: AuthService,
		private messagingService: MessagingService,
		public alertController: AlertController,
		private i18nService: I18nService,
		private router: Router
	) {}

	public initListener(): void {
		if (this.activeLoggedInUser$) {
			return null;
		}

		this.activeLoggedInUser$ = this.authService.loggedInUserId.subscribe(
			(userId: string) => {
				this.unsubscribeMessaging();
				if (userId) {
					this.activeMessaging$ = this.messagingService
						.listUnread(userId)
						.pipe(
							filter(messages => messages?.length > 0),
							distinct()
						)
						.subscribe((messages: Messaging[]) => {
							const message = messages.shift();
							this.messagingService
								.updateIsRead(message.receiverId, message)
								.then(() => {
									if (this.shouldShowMessage()) {
										this.presentMessage(message);
									}
								});
						});
				}
			}
		);
	}

	public presentMessage(message: Messaging): Promise<void> {
		const currentLang: string = this.i18nService.getCurrentLanguage();

		let alertTitle: string = null;
		if (message[`title_${currentLang}`]) {
			alertTitle = message[`title_${currentLang}`];
		} else {
			alertTitle = message.title;
		}

		let alertMessage: string = null;
		if (message[`message_${currentLang}`]) {
			alertMessage = message[`message_${currentLang}`];
		} else {
			alertMessage = message.message;
		}

		const buttons: AlertButton[] = [
			{
				text: this.i18nService.getInstant('generic.ok')
			} as AlertButton
		];
		if (message.actions?.length) {
			message.actions.forEach(action =>
				buttons.unshift({
					text: action[`label_${currentLang}`]
						? action[`label_${currentLang}`]
						: action.label,
					handler: () => {
						const allowedHostUrl = `https://${environment.allowedAppLinkingHosts[0]}`;
						const actionUrl = action.link.startsWith(allowedHostUrl)
							? action.link.substring(allowedHostUrl.length)
							: '/';
						this.router.navigateByUrl(actionUrl, {
							replaceUrl: true
						});
						return true;
					}
				} as AlertButton)
			);
		}

		return this.alertController
			.create({
				cssClass: 'alert',
				header: alertTitle,
				message: alertMessage,
				buttons
			})
			.then(alert => alert.present());
	}

	private shouldShowMessage(): boolean {
		return !FcmService.isRegistered();
	}

	private unsubscribeMessaging(): void {
		if (this.activeMessaging$) {
			this.activeMessaging$.unsubscribe();
		}
	}
}
