import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { TimestampUtils } from '@nova-core/time/timestamp.utils';
import moment from 'moment';

@Pipe({
	name: 'firebaseDate'
})
export class FirebaseDatePipe implements PipeTransform {
	constructor(
		@Inject(LOCALE_ID) private systemLocale: string,
		private readonly i18nService: I18nService
	) {}

	public transform(
		value: Timestamp | FieldValue | null,
		format?: string,
		hasTime?: boolean,
		timezone?: string,
		locale?: string,
		...args: any[]
	): string {
		if (value) {
			let momentValue = moment.utc();
			if (Object.prototype.hasOwnProperty.call(value, 'seconds')) {
				momentValue = TimestampUtils.toMoment(value as Timestamp);
				// Timestamps without time are always stored in UTC with time 00:00:00, so force timezone to UTC
				// in this case, to always show the correct date independent of the local user timezone
				if (hasTime === false) {
					timezone = 'UTC';
				}
			}

			// Check for format keywords. Allowed values are: date, dateLong, dateTime, dateTimeLong, time
			if (format) {
				format = this.i18nService
					.getInstant(`generic.dateFormat_${format}`)
					.replace('generic.dateFormat_', '');
			}

			const pipe = new DatePipe(this.systemLocale);
			return pipe.transform(
				// Use UTC ISO, since the timezone is handled in DatePipe
				momentValue?.toISOString(),
				format,
				timezone,
				locale
			);
		}

		return '';
	}
}
