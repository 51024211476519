import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@nova-shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';

import { AppBannerComponent } from './app-banner.component';
import { AppBannerService } from './app-banner.service';

@NgModule({
	providers: [AppBannerService],
	declarations: [AppBannerComponent],
	imports: [
		CommonModule,
		RouterModule,
		IonicModule,
		DirectivesModule,
		TranslateModule
	]
})
export class AppBannerModule {}
