import { FieldValue, serverTimestamp, Timestamp } from '@angular/fire/firestore';

export interface MessagingAction {
	label: string;
	label_en: string;
	link: string;
}

export class Messaging {
	constructor(
		public id: string,
		public companyId: string,
		public locationId: string,
		public eventId: string | null,
		public createdAt: Timestamp | FieldValue,
		public receiverId: string,
		public deviceId: string | null,
		public title: string,
		public title_en: string,
		public message: string,
		public message_en: string,
		public mailContent: string | null,
		public mailContent_en: string | null,
		public actions: MessagingAction[] | null,
		public isRead: boolean
	) {}

	public static build(
		companyId: string,
		locationId: string,
		eventId: string | null,
		receiverId: string,
		title: string,
		title_en: string,
		message: string,
		message_en: string,
		mailContent?: string | null,
		mailContent_en?: string | null,
		actions?: MessagingAction[] | null
	): Messaging {
		return new Messaging(
			null,
			companyId,
			locationId,
			eventId ?? null,
			serverTimestamp(),
			receiverId,
			null,
			title,
			title_en,
			message,
			message_en,
			mailContent ?? null,
			mailContent_en ?? null,
			actions ?? null,
			false
		);
	}
}
