<ion-modal
	[isOpen]="isLightboxOpened"
	[canDismiss]="true"
	[backdropDismiss]="true"
	class="transparent lightbox-modal"
>
	<ng-template>
		<ion-content [scrollY]="false">
			<div slot="fixed" class="wrapper">
				<div *ngIf="isLoadingLightbox" class="loading-photo">
					<ion-spinner color="light"></ion-spinner>
				</div>

				<swiper
					*ngIf="photos?.length || imgSrc"
					[config]="lightboxSwiperConfig"
				>
					<ng-container *ngIf="photos?.length; else singleImgSrc">
						<ng-template swiperSlide *ngFor="let photo of photos">
							<div class="swiper-zoom-container">
								<app-photo
									[photo]="photo"
									[enableLightbox]="false"
									(loaded)="onLightboxImageDidLoad()"
									class="swiper-zoom-target"
								></app-photo>
							</div>
						</ng-template>
					</ng-container>
					<ng-template #singleImgSrc>
						<ng-template swiperSlide>
							<div class="swiper-zoom-container">
								<app-photo
									[imgSrc]="imgSrc"
									[enableLightbox]="false"
									(loaded)="onLightboxImageDidLoad()"
									class="swiper-zoom-target"
								></app-photo>
							</div>
						</ng-template>
					</ng-template>
				</swiper>

				<ion-button
					appPreventDoubleClick
					(singleClick)="hide()"
					color="light"
					shape="round"
					size="large"
					class="ion-margin close"
				>
					<ion-icon slot="icon-only" name="close"></ion-icon>
				</ion-button>
			</div>
		</ion-content>
	</ng-template>
</ion-modal>
