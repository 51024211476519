import { ApiFirestoreClient } from '@nova-core/api-client/api-firestore-client.service';
import { Observable } from 'rxjs';

import { AppVersion } from './app-version';
import { AppVersionRepository } from './app-version.repository';

export class AppVersionFirestoreRepository implements AppVersionRepository {
	public static readonly COLLECTION = 'configuration';

	constructor(private db: ApiFirestoreClient) {}

	public single(): Observable<AppVersion> {
		return this.db.item<AppVersion>(
			AppVersionFirestoreRepository.COLLECTION,
			'version'
		);
	}
}
