import { inject, InjectionToken } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { ApiFirebaseClient } from '@nova-core/api-client/api-firebase-client.service';
import { environment } from '@nova-environments/environment';

import { ServerTimeFirebaseRepository } from './server-time-firebase-repository';
import { ServerTimeMockRepository } from './server-time-mock-repository';

export const SERVER_TIME_REPOSITORY = new InjectionToken<ServerTimeRepository>(
	'ServerTimeRepository',
	{
		providedIn: 'root',
		factory: () =>
			environment.useMockData
				? new ServerTimeMockRepository()
				: new ServerTimeFirebaseRepository(inject(ApiFirebaseClient))
	}
);

export interface ServerTimeRepository {
	get(): Promise<Timestamp>;
}
