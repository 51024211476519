import { Injectable } from '@angular/core';
import {
	deleteObject,
	FullMetadata,
	getDownloadURL,
	getMetadata,
	listAll,
	ListResult,
	ref,
	Storage,
	StringFormat,
	uploadBytes,
	UploadResult,
	uploadString
} from '@angular/fire/storage';
import { instanceOfPhoto, Photo } from '@nova-shared/camera/photo';
import { DownloadService } from '@nova-shared/download/download.service';

import { UuidService } from '../uuid.service';
import { ApiClientModule } from './api-client.module';

@Injectable({
	providedIn: ApiClientModule
})
export class ApiFireStorageClient {
	constructor(
		private storage: Storage,
		private uuidService: UuidService,
		private downloadService: DownloadService
	) {}

	public generateUniqueFilename(extension: string): string {
		return `${this.uuidService.generate()}.${extension}`;
	}

	public upload(path: string, dataUrl: string): Promise<UploadResult> {
		return uploadString(
			ref(this.storage, path),
			dataUrl,
			StringFormat.DATA_URL
		);
	}

	public uploadBlob(path: string, file: Blob): Promise<UploadResult> {
		return uploadBytes(ref(this.storage, path), file);
	}

	public getMetadata(path: string): Promise<FullMetadata | null> {
		return path
			? getMetadata(ref(this.storage, path))
			: Promise.resolve(null);
	}

	public getUrl(path: string): Promise<string | null> {
		return path
			? getDownloadURL(ref(this.storage, path))
			: Promise.resolve(null);
	}

	public download(path: string, asText = false): Promise<string | null> {
		return path
			? getDownloadURL(ref(this.storage, path)).then(url =>
					this.downloadService.loadFileFromURL(url, asText)
			  )
			: Promise.resolve(null);
	}

	public exists(path: string): Promise<boolean> {
		if (!path) {
			return Promise.resolve(false);
		}

		const index = path.lastIndexOf('/');
		const file = path.substring(index + 1);
		path = index > 0 ? path.substring(0, index) : '';
		return this.list(path).then(files =>
			Promise.resolve(!!files?.items?.find(f => f?.name === file))
		);
	}

	public list(path: string): Promise<ListResult | null> {
		return path ? listAll(ref(this.storage, path)) : Promise.resolve(null);
	}

	public delete(path: string): Promise<boolean | never> {
		return path
			? deleteObject(ref(this.storage, path))
					.then(() => Promise.resolve(true))
					.catch(error => {
						if (error.code === 'storage/object-not-found') {
							return Promise.resolve(false);
						}
						throw error;
					})
			: Promise.resolve(false);
	}

	public storePhoto(photo: Photo, path: string): Promise<string> {
		if (instanceOfPhoto(photo) && photo.dataUrl) {
			return this.upload(path, photo.dataUrl).then(() =>
				Promise.resolve(path)
			);
		} else if (instanceOfPhoto(photo) && photo.reference) {
			return Promise.resolve(photo.reference);
		}

		return Promise.reject();
	}
}
