import { ApiFirebaseClient } from '@nova-core/api-client/api-firebase-client.service';

import { FaceRecognitionPhoto } from './face-recognition-photo';
import { FaceRecognitionRepository } from './face-recognition-repository';
import { FaceRecognitionStoreResult } from './face-recognition-store-result';

export class FaceRecognitionFirebaseRepository
	implements FaceRecognitionRepository
{
	private static readonly FUNCTION_VALIDATE = 'validateFaceRecognitionPhoto';
	private static readonly FUNCTION_STORE = 'storeFaceRecognitionPhoto';
	private static readonly FUNCTION_READ = 'getFaceRecognitionPhoto';
	private static readonly FUNCTION_DELETE = 'deleteFaceRecognitionPhoto';

	constructor(private fns: ApiFirebaseClient) {}

	public validate(photo: FaceRecognitionPhoto): Promise<boolean> {
		const data = {
			photo
		};

		return photo
			? this.fns.call<
					{
						photo: FaceRecognitionPhoto;
					},
					boolean
			  >(FaceRecognitionFirebaseRepository.FUNCTION_VALIDATE, data)
			: Promise.resolve(false);
	}

	public store(
		userId: string,
		photo: FaceRecognitionPhoto,
		verifiedFalseHits: string[],
		accountToDelete?: string
	): Promise<FaceRecognitionStoreResult> {
		const data = {
			userId,
			photo,
			verifiedFalseHits,
			accountToDelete
		};

		return this.fns.call<
			{
				userId: string;
				photo: FaceRecognitionPhoto;
				verifiedFalseHits: string[];
				accountToDelete: string;
			},
			FaceRecognitionStoreResult
		>(FaceRecognitionFirebaseRepository.FUNCTION_STORE, data);
	}

	public read(userId: string): Promise<FaceRecognitionPhoto> {
		const data = {
			userId
		};

		return userId
			? this.fns.call<
					{
						userId: string;
					},
					FaceRecognitionPhoto
			  >(FaceRecognitionFirebaseRepository.FUNCTION_READ, data)
			: Promise.resolve(null);
	}

	public delete(userId: string): Promise<boolean> {
		const data = {
			userId
		};

		return this.fns.call<
			{
				userId: string;
			},
			boolean
		>(FaceRecognitionFirebaseRepository.FUNCTION_DELETE, data);
	}
}
