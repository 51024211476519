import { Observable, of, throwError } from 'rxjs';

import { AppVersion } from './app-version';
import { AppVersionRepository } from './app-version.repository';

export class AppVersionMockRepository implements AppVersionRepository {
	private appVersion: AppVersion[] = [
		new AppVersion('version', '1.0.0', '1.0.0', '1.0.0', '1.0.0'),
		new AppVersion('version', '1.0.0', '1.0.0', '1.0.0', '1.0.0')
	];

	public single(): Observable<AppVersion> {
		const appVersion = this.appVersion.find(gc => gc.id === 'version');
		return appVersion
			? of(appVersion)
			: throwError(() => new Error('not found'));
	}
}
