import { Injectable } from '@angular/core';
import { Camera, CameraPermissionType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { AlertController, Platform } from '@ionic/angular';
import { I18nService } from '@nova-core/i18n/i18n.service';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

import { cameraAlertTexts } from './constants/camera-alert-texts';
import { cameraAlertTextsAndroid } from './constants/camera-alert-texts-android';
import { cameraAlertTextsIOS } from './constants/camera-alert-texts-ios';
import { detectBrowserName } from './utils/detect-browser-name';

@Injectable({
	providedIn: 'root'
})
export class DevicePermissionService {
	constructor(
		private platform: Platform,
		private alertController: AlertController,
		private i18nService: I18nService
	) {}

	public requestCameraPermission(): Promise<boolean | null> {
		return this.requestPermission('camera').then(async isPermitted => {
			if (isPermitted === false) {
				if (Capacitor.isNativePlatform()) {
					await this.showSettingsAlert(
						'devicePermission.camera',
						this.i18nService.getInstant(
							'devicePermission.theCamera'
						) as string
					);
				} else {
					await this.presentCameraWebAlert();
				}
			}
			return Promise.resolve(isPermitted);
		});
	}

	public requestPhotosPermission(): Promise<boolean | null> {
		return this.requestPermission('photos').then(async isPermitted => {
			if (isPermitted === false) {
				await this.showSettingsAlert(
					'devicePermission.photosAndMediaAndFiles',
					this.i18nService.getInstant(
						'devicePermission.photosAndMediaAndFiles'
					) as string
				);
			}
			return Promise.resolve(isPermitted);
		});
	}

	public isPermissionError(error: Error): boolean {
		return (
			error?.toString()?.toLowerCase()?.indexOf('permission') >= 0 ||
			error?.toString()?.toLowerCase()?.indexOf('denied access') >= 0 ||
			error?.toString()?.toLowerCase()?.indexOf('not allowed') >= 0
		);
	}

	public showErrorForUnsupportedClients(): Promise<void> {
		return this.presentCameraWebAlert();
	}

	public async showSettingsAlert(
		headerKey: string,
		permissionTypeText: string,
		messageKey?: string
	): Promise<void> {
		const buttons = [
			{
				text: this.i18nService.getInstant('generic.ok'),
				role: 'cancel',
				handler: () => {}
			}
		];
		if (Capacitor.isNativePlatform()) {
			buttons.push({
				text: this.i18nService.getInstant('generic.settings'),
				role: undefined,
				handler: () => {
					NativeSettings.open({
						optionAndroid: AndroidSettings.ApplicationDetails,
						optionIOS: IOSSettings.App
					});
				}
			});
		}

		const alert = await this.alertController.create({
			cssClass: 'alert',
			header: this.i18nService.getInstant(headerKey),
			message: this.i18nService.getInstant(
				messageKey ?? 'devicePermission.messages.accessBlocked',
				{ permissionTypeText }
			),
			buttons
		});

		return alert.present();
	}

	private requestPermission(
		permission: CameraPermissionType
	): Promise<boolean | null> {
		return Camera.requestPermissions({
			permissions: [permission]
		})
			.then(permissionResult => {
				if (
					permissionResult[permission] === 'granted' ||
					permissionResult[permission] === 'prompt' ||
					permissionResult[permission] === 'limited'
				) {
					return Promise.resolve(true);
				} else if (permissionResult[permission] === 'denied') {
					return Promise.resolve(false);
				} else {
					// If the user denies on Android, it sometimes has 'prompt-with-rationale'. So just use denied for any other state
					return Promise.resolve(false);
				}
			})
			.catch(() => Promise.resolve(null));
	}

	private async presentCameraWebAlert(): Promise<void> {
		const browser = detectBrowserName();

		const texts = this.platform.is('ios')
			? cameraAlertTextsIOS
			: this.platform.is('android')
			? cameraAlertTextsAndroid
			: cameraAlertTexts;
		const cameraAlert = texts.find(m => m.browser === browser);

		const alert = await this.alertController.create({
			cssClass: 'alert',
			header: await this.i18nService.get(
				'devicePermission.webcamAuthorisation'
			),
			subHeader: await this.i18nService.get(cameraAlert.subHeader),
			message: await this.i18nService.get(cameraAlert.message),
			buttons: [await this.i18nService.get('generic.ok')]
		});

		return alert.present();
	}
}
