import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { ErrorHandlingService } from '@nova-core/error-handling.service';
import { environment } from '@nova-environments/environment';
import moment from 'moment';
import { firstValueFrom, interval, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, takeUntil, takeWhile } from 'rxjs/operators';

import { SERVER_TIME_REPOSITORY, ServerTimeRepository } from './server-time-repository';
import { TimestampUtils } from './timestamp.utils';

@Injectable({
	providedIn: 'root'
})
export class TimeService implements OnDestroy {
	private static readonly MAX_OUT_OF_SYNC_SEC = 21600; // 6 hours to recognize client time manipulation

	public serverTimestamp: Observable<Timestamp>;
	private serverTimestampSubject = new ReplaySubject<Timestamp>(1);
	private timeSubscription: Subscription;
	private inited = false;
	private destruction = new Subject<void>();

	constructor(
		@Inject(SERVER_TIME_REPOSITORY)
		private readonly repository: ServerTimeRepository,
		private readonly errorHandlingService: ErrorHandlingService
	) {
		this.serverTimestamp = this.serverTimestampSubject.asObservable();
	}

	public ngOnDestroy(): void {
		this.destruction.next();
		this.destruction.complete();
	}

	public init(): void {
		if (!this.inited) {
			this.inited = true;
			this.refetchServerTime();
		}
	}

	public refetchServerTime(): void {
		if (this.timeSubscription) {
			this.timeSubscription.unsubscribe();
		}

		this.repository
			.get()
			.then(serverTime => {
				const clientTime = Timestamp.now();
				const startedClientSeconds = clientTime.seconds;
				const startedServerSeconds = (serverTime ?? clientTime).seconds;
				const serverClientSecondsDiff =
					startedServerSeconds - startedClientSeconds;

				if (environment.clientLogEnabled) {
					console.log(
						`Synced server time: ${serverTime?.toDate()} - Client diff: ${serverClientSecondsDiff}sec`
					);
				}

				this.serverTimestampSubject.next(serverTime ?? clientTime);

				this.timeSubscription = interval(1000)
					.pipe(
						takeWhile(secondsDiff =>
							this.isClientTimeInSync(
								startedClientSeconds,
								secondsDiff
							)
						),
						map(() =>
							Timestamp.fromMillis(
								Timestamp.now().toMillis() +
									serverClientSecondsDiff * 1000
							)
						),
						takeUntil(this.destruction)
					)
					.subscribe(time => this.serverTimestampSubject.next(time));
			})
			.catch(error => {
				if (environment.clientLogEnabled) {
					console.error('Error fetching server time', error);
				}

				// The server time handler is used as heartbeat. If its not working, something fundamental is wrong,
				// like missing internet connection. So handle it as fatal error.
				this.errorHandlingService.handleFatalError(error, null, true);
			});
	}

	public serverTimestampAsPromise(): Promise<Timestamp> {
		return firstValueFrom(this.serverTimestamp);
	}

	public async isDayEqual(
		from: Timestamp | FieldValue | null,
		to: Timestamp | FieldValue | null
	): Promise<boolean> {
		return Promise.resolve(
			TimestampUtils.isDayEqual(
				await this.toTimestamp(from),
				await this.toTimestamp(to)
			)
		);
	}

	public async isMonthEqual(
		from: Timestamp | FieldValue | null,
		to: Timestamp | FieldValue | null
	): Promise<boolean> {
		return Promise.resolve(
			TimestampUtils.isMonthEqual(
				await this.toTimestamp(from),
				await this.toTimestamp(to)
			)
		);
	}

	public async isCurrentYear(
		timestamp: Timestamp | FieldValue | null
	): Promise<boolean> {
		return Promise.resolve(
			TimestampUtils.isCurrentYear(
				await this.toTimestamp(timestamp),
				await this.serverTimestampAsPromise()
			)
		);
	}

	public toTimestamp(
		value: Timestamp | FieldValue | null
	): Promise<Timestamp | null> {
		if (value instanceof Timestamp) {
			return Promise.resolve(value);
		} else if (value instanceof FieldValue) {
			return this.serverTimestampAsPromise();
		}

		return Promise.resolve(null);
	}

	public async serverTimestampToMoment(): Promise<moment.Moment | null> {
		return this.serverTimestampAsPromise().then(t =>
			TimestampUtils.toMoment(t)
		);
	}

	public async serverTime(): Promise<Timestamp | null> {
		return this.serverTimestampAsPromise().then(t =>
			TimestampUtils.clearSeconds(t)
		);
	}

	public async serverTimeToMoment(): Promise<moment.Moment | null> {
		return this.serverTime().then(t => TimestampUtils.toMoment(t));
	}

	public async serverDate(): Promise<Timestamp | null> {
		return this.serverTimestampAsPromise().then(t =>
			TimestampUtils.clearTime(t)
		);
	}

	public async serverDateToMoment(): Promise<moment.Moment | null> {
		return this.serverDate().then(t => TimestampUtils.toMoment(t));
	}

	private isClientTimeInSync(
		startedClientSeconds: number,
		secondsThatShouldPassed: number
	): boolean {
		const currentDiff = Timestamp.now().seconds - startedClientSeconds;
		if (
			Math.abs(secondsThatShouldPassed - currentDiff) >
			TimeService.MAX_OUT_OF_SYNC_SEC
		) {
			if (environment.clientLogEnabled) {
				console.log(
					`Server / Client time out of sync: ${
						secondsThatShouldPassed - currentDiff
					}sec since last fetch`
				);
			}
			this.refetchServerTime();
			return false;
		}
		return true;
	}
}
